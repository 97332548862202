<div class="ticket-card-list box-shadow" [ngStyle]="{'background-color': backColor, 'color': fontColor}">
  <div class="ticket-card-list-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <i (click)="toggleCollapsed($event)" class="fas fa-chevron-left"></i>
      @if (!state.editable) {
        <span (dblclick)="state.editable = !state.editable" [ngStyle]="{color: fontColor}">
          {{ state.name }} ({{ state.boardTickets?.length ?? 0 }})
        </span>
      } @else {
        <input
          (dblclick)="state.editable = !state.editable"
          [ngStyle]="{color: fontColor}"
          [(ngModel)]="state.name"
          [placeholder]="'BOARD.LIST_NAME' | translate"
          (keyup.enter)="updateBoardState()"
          class="board-state-name-input"
        />
        <span>
          <i [ngStyle]="{color: fontColor}" (click)="updateBoardState()" class="fas fa-save"></i>
        </span>
      }

      <ng-template #editable>
        <input
          (dblclick)="state.editable = !state.editable"
          [ngStyle]="{color: fontColor}"
          [(ngModel)]="state.name"
          [placeholder]="'BOARD.LIST_NAME' | translate"
          (keyup.enter)="updateBoardState()"
          class="board-state-name-input"
        />
        <span>
          <i [ngStyle]="{color: fontColor}" (click)="updateBoardState()" class="fas fa-save"></i>
        </span>
      </ng-template>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon [ngStyle]="{color: fontColor}">more_vert</mat-icon>
    </button>
  </div>
  <mat-menu #menu="matMenu">
    <div mat-menu-item (click)="openTicketDialog()">{{ 'BOARD.ADD_EXISTING_TICKETS' | translate }}</div>
    <div mat-menu-item (click)="openNewTicketDialog()">{{ 'BOARD.ADD_NEW_TICKET' | translate }}</div>
    <div mat-menu-item (click)="state.editable = !state.editable">{{ 'BOARD.RENAME' | translate }}</div>
    <div mat-menu-item (click)="openDialog()">{{ 'GLOBAL.DELETE' | translate }}</div>
    <div mat-menu-item (click)="openBoardActionMenu()">{{ 'GLOBAL.SETTINGS' | translate }}</div>
  </mat-menu>

  @if (!isCollapsed) {
    <cdk-virtual-scroll-viewport [ngStyle]="{height: fullHeight + 'px'}" itemSize="120" class="ticket-card-list-viewport">
      <ng-content></ng-content>
    </cdk-virtual-scroll-viewport>
  }
</div>
