<omt-resource-scheduler-calendar
  class="mat-elevation-z4"
  [viewDate]="viewDate"
  (viewDateChange)="handleViewDateChange($event)"
  (entryAdded)="addEntry($any($event))"
  (entryMoved)="updateEntry($any($event))"
  (entryReset)="resetEntry($any($event))"
  [resources]="resourceTickets$ | async"
  [units]="assignees$ | async"
  [entries]="entries$ | async"
  [unitTemplate]="assigneeTemplate"
  [calendarEntryTemplate]="entryTemplate"
></omt-resource-scheduler-calendar>

<ng-template #assigneeTemplate let-assignee>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
    <omt-avatar [avatarUrl]="assignee.avatarUrl" size="30"></omt-avatar>
    <span>{{ assignee.name | empty }}</span>
  </div>
</ng-template>

<ng-template #entryTemplate let-entry>
  <div class="calendar-entry" [style.background-color]="entry.ticketId ? entry.color : '#a719d0'">
    <div class="calendar-entry-name">{{ entry.name }}</div>
  </div>
</ng-template>

@if (isLoading) {
  <omt-spinner></omt-spinner>
}
