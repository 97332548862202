import {Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '@core/services/global/dialog.service';
import {CdkDragEnter, moveItemInArray} from '@angular/cdk/drag-drop';
import {timer} from 'rxjs';
import {SubSink} from 'subsink';
import {BoardSelectFilter} from '@boards/components/board-select/board-select-filter/board-select-filter';
import {BusinessUnitModel} from '@core/models/businessunit/business-unit.model';
import {BusinessUnitService} from '@core/services/global/business-unit.service';
import {ProjectStatusEnum} from '@core/enums/project-status.enum';
import {BoardHttpService} from '@boards/board-http.service';
import {BoardModel} from '@boards/models';
import {ProjectModel} from '@app/project-management/models';
import {ProjectHttpService} from '@app/project-management/project-http.service';
import {stringIsNullOrWhiteSpace} from '@core/utils/string-utils';

@Component({
    selector: 'omt-board-select-row',
    templateUrl: './board-select-row.component.html',
    styleUrls: ['./board-select-row.component.scss']
})
export class BoardSelectRowComponent implements OnInit {
    @HostBinding('class') cssClass = 'board-select-row';

    @Input() set boards(boards: BoardModel[]) {
        this._boards = [...boards];
    }

    get boards(): BoardModel[] {
        return this._boards;
    }

    private _boards: BoardModel[];

    @Input() title = 'Boards';
    @Input() teamBoard = false;
    @Input() projectBoard = false;
    @Input() filter: BoardSelectFilter;

    @ViewChild('container', {static: true}) container: ElementRef;

    private subs = new SubSink();

    isLoading = false;
    isUserBoard = !this.teamBoard && !this.projectBoard;
    businessUnits: BusinessUnitModel[] = [];
    projects: ProjectModel[] = [];
    cols = 6;

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    constructor(private readonly dialogService: DialogService,
                private readonly boardService: BoardHttpService,
                private readonly buSerivce: BusinessUnitService,
                private readonly projectService: ProjectHttpService) {
    }

    ngOnInit(): void {
        this.reCalcSize();

        this.subs.sink = this.buSerivce.getAllBusinessUnits().subscribe((businessUnits) => {
            this.businessUnits = businessUnits;
        });

        if (this.projectBoard) {
            this.isLoading = true;
            this.subs.sink = this.projectService.readAvailableProjects().subscribe((x) => {
                this.projects = x;
                this.isLoading = false;
            }, () => this.isLoading = false);
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    openDialog(isTeam: boolean, isProject: boolean): void {
        const dialogRef = this.dialogService.openBoardCreateDialog({isTeam, isProject});
    }

    entered(event: CdkDragEnter): void {
        moveItemInArray(this.boards, event.item.data, event.container.data);
    }

    dropped(): void {
        let i = 1;
        const boardPositions: number[] = [];
        this.boards.forEach((value) => {
            boardPositions.push(value.id);
            value.position = i;
            i++;
        });

        this.subs.sink = this.boardService.updateBoardPositionList(boardPositions).subscribe();
    }

    reCalcSize(): void {
        const fullwitdth = this.getFullWidth();
        this.cols = Math.floor(fullwitdth / 242);
    }

    getFullWidth(): number {
        if (this.container.nativeElement.offsetWidth === 0) {
            timer(50).subscribe((x) => {
                this.getFullWidth();
            });

            return 0;

        } else {
            return this.container.nativeElement.offsetWidth;
        }

    }

    getBusinessUnitName(buID: number): string | null {
        return this.businessUnits?.find((bu) => bu.id === buID)?.displayName;
    }

    getDisplayedProjectBoards(): ProjectModel[] {
        let returnProjects = this.filter.filterByDoneProjects ? this.projects.filter((x) => x.statusId !== ProjectStatusEnum.Abgeschlossen) : this.projects;

        if (!stringIsNullOrWhiteSpace(this.filter.filterText)) {
            returnProjects = returnProjects.filter((x) => x.name?.toLowerCase()?.includes(this.filter.filterText));
        }

        return returnProjects;
    }

    showImageTextOverlay(board: BoardModel): boolean {
        return board.boardImageUrl?.length > 4;
    }
}
