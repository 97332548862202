import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TimeBoardAssignee} from '@boards/components/time-board/time-board-user-scheduler/time-board-assignee';
import {HttpParams} from '@angular/common/http';
import {BusinessUnitModel} from '@core/models/businessunit/business-unit.model';
import {map} from 'rxjs/operators';
import {ApiService} from '@core/services/api.service';
import {BoardTicketAssignRequest} from '@boards/components/time-board/time-board-user-scheduler/board-ticket-assign-request';
import {BoardTicketModel} from '@boards/models';
import {TimeBoardBlockingEvents} from '@boards/components/time-board/time-board-user-scheduler/time-board-blocking-events';

@Injectable({
    providedIn: 'root'
})
export class TimeBoardSchedulerHttpService {
    constructor(private readonly apiService: ApiService) {
    }

    assignResource(request: BoardTicketAssignRequest): Observable<BoardTicketModel> {
        return this.apiService.post('api/BoardScheduler/AssignResource', request);
    }

    getResources(boardId: number): Observable<TimeBoardAssignee[]> {
        const params = new HttpParams().set('boardId', boardId.toString());
        return this.apiService.get<BusinessUnitModel[]>('api/BoardScheduler/GetResources', params)
            .pipe(map((x) => {
                const mappedResult = [];
                x.forEach((assignee) => assignee.userId != null ? mappedResult.push(new TimeBoardAssignee(assignee)) : void (0));
                return mappedResult;
            }));
    }

    getBlockingEvents(boardId: number, {start, end}: { start: Date; end: Date }): Observable<TimeBoardBlockingEvents> {
        const params = new HttpParams().set('boardId', boardId.toString()).set('start', start.toDateString()).set('end', end.toDateString());
        return this.apiService.get('api/BoardScheduler/GetBlockingEvents', params);
    }

    updateAssignee(boardTicketId: number, assigneeId: number): Observable<any[]> {
        const params = new HttpParams()
            .set('boardTicketId', boardTicketId.toString())
            .set('assigneeId', assigneeId.toString());

        return this.apiService.put<any[]>('api/BoardScheduler/UpdateAssignee', {}, {params});
    }

    updateDateTime(boardTicketId: number, start: Date, end: Date): Observable<any[]> {
        const params = new HttpParams()
            .set('boardTicketId', boardTicketId.toString())
            .set('start', start.toUTCString())
            .set('end', end.toUTCString());

        return this.apiService.put<any[]>('api/BoardScheduler/UpdateDateTime', {}, {params});
    }
}
