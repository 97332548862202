import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BoardSelectFilter} from '@boards/components/board-select/board-select-filter/board-select-filter';

@Component({
    selector: 'omt-board-select-filter',
    templateUrl: './board-select-filter.component.html',
    styleUrls: ['./board-select-filter.component.scss']
})
export class BoardSelectFilterComponent implements OnInit {
    @Input() filter = new BoardSelectFilter();
    @Output() filterChange = new EventEmitter<BoardSelectFilter>();
    @Output() selectedBoardTypesChange = new EventEmitter<BoardSelectFilter>();

    constructor() {
    }

    ngOnInit(): void {
        this.filter = JSON.parse(localStorage.getItem(BoardSelectFilter.localStorageKey)) ?? this.filter;
    }

    onChange(): void {
        localStorage.setItem(BoardSelectFilter.localStorageKey, JSON.stringify(this.filter));
        this.filterChange.emit(this.filter);
    }
}
