<mat-sidenav-container style="height: 100%" [ngStyle]="{'background-color': boardTransferModel.secondAccentColor}">
  <div class="page-layout simple redesign" id="tickets">
    <omt-board-header [boardTransferModel]="boardTransferModel" (settingsToggled)="settingsSidenav.toggle()">
      <ng-container actions-left>
        <div>
          <button
            mat-icon-button
            class="arrow"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="tbs.viewDate"
            (viewDateChange)="tbs.selectedDay = {date: $event}; tbs.schedulerTimeEvent.emit('prev')"
            matTooltip="Zurück"
            aria-label="Previous"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>

          <button
            mat-icon-button
            class="arrow"
            mwlCalendarToday
            [(viewDate)]="tbs.viewDate"
            (viewDateChange)="tbs.selectedDay = {date: $event}; tbs.schedulerTimeEvent.emit('today')"
            matTooltip="Heute"
            aria-label="Today"
          >
            <mat-icon>expand_more</mat-icon>
          </button>

          <button
            mat-icon-button
            class="arrow"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="tbs.viewDate"
            (viewDateChange)="tbs.selectedDay = {date: $event}; tbs.schedulerTimeEvent.emit('next')"
            matTooltip="Vor"
            aria-label="Next"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </ng-container>

      <ng-container actions-right>
        <div>
          <button mat-icon-button (click)="view = day" aria-label="Day" matTooltip="Tag">
            <mat-icon>view_day</mat-icon>
          </button>

          <button mat-icon-button (click)="view = week" aria-label="Week" matTooltip="Woche">
            <mat-icon>view_week</mat-icon>
          </button>

          <button mat-icon-button (click)="view = month" aria-label="Month" matTooltip="Monat">
            <mat-icon>view_module</mat-icon>
          </button>
        </div>
      </ng-container>
    </omt-board-header>

    <div>
      @switch (view) {
        @case (day) {
          <omt-time-board-day></omt-time-board-day>
        }
        @case (week) {
          <omt-time-board-week></omt-time-board-week>
        }
        @case (month) {
          <omt-time-board-month></omt-time-board-month>
        }
        @case (scheduler) {
          <omt-time-board-user-scheduler [board]="boardTransferModel"></omt-time-board-user-scheduler>
        }
      }
    </div>
  </div>

  <mat-sidenav #settingsSidenav position="end">
    <omt-board-sidenav [boardTransferModel]="boardTransferModel" (reloadTickets)="tbs.reloadBoard$.next()"></omt-board-sidenav>
  </mat-sidenav>
</mat-sidenav-container>
