import {BoardTicketModel} from '@boards/models';
import {TimeBoardResourceTicket} from '@boards/components/time-board/time-board-user-scheduler/time-board-resource-ticket';
import {TimeBoardScheduledEvent} from '@boards/components/time-board/time-board-user-scheduler/time-board-scheduled-event';
import {CalendarEventModel} from '@time-tracking/calendar/models';
import {dateRange, datesAreOnSameDay, setStartAndEnd} from '@core/utils/date-utils';
import {getColorFromTicketStatus} from '@core/utils/get-color-from-ticket-status';

export class TimeBoardEventMapper {
    static mapBoardTickets(boardTickets: BoardTicketModel[]): { unassigned: TimeBoardResourceTicket[]; scheduled: TimeBoardScheduledEvent[] } {
        const unassigned = [];
        const scheduled = [];

        boardTickets.forEach((ticket) => {
            if (!ticket.dateFrom || !ticket.dateTo) {
                unassigned.push(new TimeBoardResourceTicket(ticket));
                return;
            }


            const scheduledTicket = TimeBoardEventMapper.mapTicketToEvent(ticket);
            scheduled.push(scheduledTicket);
        });

        return {unassigned, scheduled};
    }

    static mapCalendarEvents(calendarEvents: CalendarEventModel[]): TimeBoardScheduledEvent[] {
        const scheduled = [];

        calendarEvents.forEach((event) => {
            event.start = new Date(event.start);
            event.end = new Date(event.end);

            if (datesAreOnSameDay(event.start, event.end)) {
                scheduled.push(new TimeBoardScheduledEvent(null, event.businessUnitId, event.title, event.start, event.end, false));
                return;
            }

            // create an entry for each day from event start till end
            const dates = dateRange(event.start, event.end);
            dates.forEach((date) => {
                const startAndEnd = setStartAndEnd(date, {startHour: 0, endHour: 23});
                scheduled.push(new TimeBoardScheduledEvent(null, event.businessUnitId, event.title, startAndEnd.start, startAndEnd.end, false));
            });

        });

        return scheduled;
    }

    static mapBlockingTickets(boardTickets: BoardTicketModel[]): TimeBoardScheduledEvent[] {
        const scheduled = [];

        boardTickets.forEach((boardTicket) => {
            const blockingTicket = new TimeBoardScheduledEvent(boardTicket.ticketId, boardTicket.ticket.assigneeId, boardTicket.ticket.title,
                new Date(boardTicket.dateFrom), new Date(boardTicket.dateTo), false, new TimeBoardResourceTicket(boardTicket));

            blockingTicket.color = getColorFromTicketStatus(boardTicket.ticket.ticketState.id);
            scheduled.push(blockingTicket);
        });

        return scheduled;
    }

    static mapTicketToEvent(ticket: BoardTicketModel): TimeBoardScheduledEvent {
        const event = new TimeBoardScheduledEvent(ticket.ticketId, ticket.ticket.assigneeId, ticket.ticket.title, new Date(ticket.dateFrom), new Date(ticket.dateTo), true, new TimeBoardResourceTicket(ticket));
        event.color = getColorFromTicketStatus(ticket.ticket.ticketState.id);
        return event;
    }
}
