<div style="width: 100%">
  <mat-sidenav-container style="height: 100%">
    <div class="page-layout simple redesign" id="tickets" (window:resize)="resize($event)">
      <omt-assigned-ticket-board-header #header [title]="title" (settingsToggled)="settingsSidenav.toggle()">
        @if (customerId) {
          <omt-customer-dropdown class="w-250" [selectedCustomerId]="customerId" (selectedCustomerChange)="customerChange($event)" [clearable]="false"/>
        }
        @if (userId) {
          <omt-business-unit-dropdown class="w-250" showType="users" bindValue="id" [(value)]="userId" (valueChange)="userChange($event)" [clearable]="false"/>
        }
        @if (projectId) {
          <omt-project-dropdown class="w-250" [selectedProjectId]="projectId" (selectedProjectChange)="projectChange($event)" [clearable]="false"/>
        }
      </omt-assigned-ticket-board-header>
      <div class="content-spacer pt-0 pb-1" fusePerfectScrollbar>
        <div fxLayout="row" fxLayoutAlign="start start">
          <div fxLayout="row">
            @for (state of states | sortBy: 'asc': 'position'; track state) {
              <omt-actionless-board-state [fullHeight]="fullHeight" [state]="state" style="margin-top: 7px">
                <div class="ticket-state-drop-placeholder" *cdkDragPlaceholder></div>
                <div
                  [id]="state.id.toString()"
                  cdkDropList
                  [cdkDropListConnectedTo]="states | stateId"
                  [cdkDropListData]="state.tickets"
                  (cdkDropListDropped)="dropTicket(state, $event)"
                  fxLayout="column"
                  fxLayoutAlign="start center"
                  fxLayoutGap="1rem"
                >
                  @for (ticket of state.tickets; track ticket) {
                    <omt-ticket-card [ticket]="ticket" cdkDrag>
                      <div class="ticket-card-drop-placeholder" *cdkDragPlaceholder></div>
                    </omt-ticket-card>
                  }
                </div>
              </omt-actionless-board-state>
            }
          </div>
        </div>
      </div>
    </div>

    <mat-sidenav #settingsSidenav position="end">
      <omt-assigned-ticket-side-nav
        [customers]="customers"
        [projects]="projects"
        [users]="users"
        [(userFilter)]="userFilter"
        (userFilterChange)="filter()"
        [(customerFilter)]="customerFilter"
        (customerFilterChange)="filter()"
        [(projectFilter)]="projectFilter"
        (projectFilterChange)="filter()"
        [mode]="mode"
      ></omt-assigned-ticket-side-nav>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
