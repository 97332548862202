import {Component} from '@angular/core';
import {TimeBoardService} from '@app/boards/components/time-board/time-board.service';

@Component({
    selector: 'omt-time-board-day',
    templateUrl: './time-board-day.component.html',
    styleUrls: ['./time-board-day.component.scss']
})
export class TimeBoardDayComponent {
    constructor(public tbs: TimeBoardService) {
    }
}
