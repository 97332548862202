import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, TrackByFunction} from '@angular/core';
import {addDays, dateRange, filterWeekEnds, setStartAndEnd} from '@core/utils/date-utils';
import {CalendarUnit} from '@core/components/resource-scheduler-calendar/calendar-unit';
import {CalendarResource} from '@core/components/resource-scheduler-calendar/calendar-resource';
import {CalendarEntry} from '@core/components/resource-scheduler-calendar/calendar-entry';
import {CdkDragDrop, DragRef, moveItemInArray, Point} from '@angular/cdk/drag-drop';
import {SubSink} from 'subsink';
import {interval} from 'rxjs';
import {getHourFromTimeSegmentElement, isEntry} from '@core/components/resource-scheduler-calendar/calendar-utils';
import {OmtDateRange} from '@core/components/datepicker/date-picker-date.type';
import {ResourceCalendar} from '@core/components/resource-scheduler-calendar/resource-calendar';
import {CalendarZone} from '@core/components/resource-scheduler-calendar/calendar-zone';
import {CalendarDay} from '@core/components/resource-scheduler-calendar/calendar-day';
import {v4} from 'uuid';

@Component({
    selector: 'omt-resource-scheduler-calendar',
    templateUrl: './resource-scheduler-calendar.component.html',
    styleUrls: ['./resource-scheduler-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceSchedulerCalendarComponent implements OnInit, OnDestroy {
    @Input() set units(units: CalendarUnit[]) {
        this.calendar.units = units;
    }

    @Input() set viewDate(date: Date) {
        if (!date) {
            return;
        }

        this._viewDate = new Date(date);
        this.viewDateChange.emit(this._viewDate);
        this.setDisplayDate();
    }

    get viewDate(): Date {
        return this._viewDate;
    }

    private _viewDate = new Date();

    @Output() viewDateChange = new EventEmitter<Date>();

    @Input() set displayHours(hours: number[]) {
        this._displayHours = [...hours];
        this.calendar.displayHours = [...hours];
    }

    get displayHours(): number[] {
        return this._displayHours;
    }

    private _displayHours = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

    get lastDisplayHour(): number {
        return this._displayHours[this._displayHours.length - 1];
    }

    activeHour: number;
    current = new Date();
    hourContainerClass = 'hour-segments';
    calendar = new ResourceCalendar([], [], this.displayHours);

    @Input() set displayWeekEnds(display: boolean) {
        this._displayWeekEnds = display;
        this.setDisplayDate();
    }

    private _displayWeekEnds = false;

    @Input() set resources(resources: CalendarResource[]) {
        this.calendar.resources = resources;
    }

    @Input() set entries(entries: CalendarEntry[]) {
        this.calendar.entries = entries;
        this._entries = entries;
    }

    private _entries = [];

    @Output() entryAdded = new EventEmitter<CalendarEntry>();
    @Output() entryMoved = new EventEmitter<CalendarEntry>();
    @Output() entryReset = new EventEmitter<CalendarEntry>();
    @Output() entryClicked = new EventEmitter<CalendarEntry>();

    @Input() calendarEntryTemplate: TemplateRef<any>;
    @Input() placeholderTemplate: TemplateRef<any>;
    @Input() unitTemplate: TemplateRef<any>;
    @Input() trackResourceByFn: TrackByFunction<CalendarResource>;

    currentDragEvent: { startHour: number; endHour: number };
    private _defaultEventLength = 3;

    private subs = new SubSink();

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    constructor() {
    }

    ngOnInit(): void {
        this.setActiveHour();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ View
    // -----------------------------------------------------------------------------------------------------

    prevDate(): void {
        this.viewDate = addDays(this.viewDate, -7);
    }

    nextDate(): void {
        this.viewDate = addDays(this.viewDate, 7);
    }

    trackByFn(index: number, item: CalendarEntry): string {
        return item.uniqueId;
    }

    /**
     * Snaps to the nearest hour segment upon dragging an entry or resource. Also stores a reference of the event start and end hours,
     * according to the hour segments which the item is dropped onto.
     *
     * @param point
     * @param dragRef
     */
    constrainFn = (point: Point, dragRef: DragRef): Point => {
        const elemFromCoords = document.elementFromPoint(point.x, point.y);

        if (!elemFromCoords) {
            return point;
        }

        const timeContainer = elemFromCoords.parentElement.lastChild as HTMLElement;

        if (!timeContainer || !timeContainer.classList || !timeContainer.classList.contains(this.hourContainerClass)) {
            return point;
        }

        const timeSegments = Array.from(timeContainer.children);
        const nearestTimeSegment = this.getNearestTimeSegment(timeSegments, point);
        const nearestTimeSegmentRect = nearestTimeSegment.getBoundingClientRect();

        const placeholder = dragRef.getPlaceholderElement();
        const placeholderLeft = point.x - timeContainer.getBoundingClientRect().left;
        placeholder.style.left = `${placeholderLeft}px`;

        const startHour = getHourFromTimeSegmentElement(nearestTimeSegment);

        const originalStartEndDifference = isEntry(dragRef.data.data) ? Math.abs(dragRef.data.data.to.getHours() - dragRef.data.data.from.getHours()) : this._defaultEventLength;
        const startWithAddedDifference = startHour + originalStartEndDifference;
        const endHour = startWithAddedDifference > this.lastDisplayHour ? this.lastDisplayHour : startWithAddedDifference;
        this.currentDragEvent = {startHour, endHour};

        return {x: nearestTimeSegmentRect.x, y: point.y};
    };

    // -----------------------------------------------------------------------------------------------------
    // @ Event Handling
    // -----------------------------------------------------------------------------------------------------

    handleDrop(dropEvent: CdkDragDrop<CalendarResource, CalendarEntry>, targetDay: CalendarDay, zone: CalendarZone): void {
        let startAndEnd = new OmtDateRange(targetDay.date, targetDay.date);

        if (this.currentDragEvent) {
            startAndEnd = setStartAndEnd(targetDay.date, {startHour: this.currentDragEvent.startHour, endHour: this.currentDragEvent.endHour});
            this.currentDragEvent = null;
        }

        const isSameContainer = dropEvent.item.dropContainer === dropEvent.container;

        if (isSameContainer) {
            moveItemInArray(targetDay.events, dropEvent.previousIndex, dropEvent.currentIndex);
            this.updateInZone(dropEvent, startAndEnd);
            return;
        }

        if (!isEntry(dropEvent.item.data)) {
            this.addEntry(startAndEnd, dropEvent, targetDay, zone);
            return;
        }

        this.moveToZone(dropEvent, startAndEnd, targetDay, zone);
    }

    handleResourceDrop(dropEvent: CdkDragDrop<any>): void {
        if (dropEvent.container === dropEvent.previousContainer) {
            return;
        }

        const entry = dropEvent.item.data as CalendarEntry;
        this.removeEntry(entry);

        // TODO fix index mismatch after first drop
        // console.log(dropEvent.currentIndex);
        this.calendar.addResource(entry.resource, dropEvent.currentIndex);
        this.entryReset.emit(entry);
    }

    handleResize($event: { edges; rectangle }, elem: HTMLElement, entry: CalendarEntry): void {
        const leftEdgeResized = $event.edges.left != null;
        const x = leftEdgeResized ? $event.rectangle.left : $event.rectangle.right;
        const hourElements = Array.from((elem.parentElement.parentElement.lastChild as HTMLElement).children);
        const nearestTimeSegment = this.getNearestTimeSegment(hourElements, {x}, leftEdgeResized);
        const hour = getHourFromTimeSegmentElement(nearestTimeSegment);
        const dateToUpdate = leftEdgeResized ? entry.from : entry.to;
        dateToUpdate.setHours(hour);

        const updatedEntry = {...entry, from: entry.from, to: entry.to};
        this.updateEntry(entry, updatedEntry);
        this.entryMoved.emit(updatedEntry);
    }

    resourceSearchFilter(search: string): void {
        this.calendar.filter = search;
    }

    isWeekend(date: Date): boolean {
        const dayOfWeek = date.getDay();
        return dayOfWeek === 0 || dayOfWeek === 6;
    }

    private updateInZone(dropEvent: CdkDragDrop<CalendarResource, CalendarEntry>, targetDate: OmtDateRange): void {
        const entry = dropEvent.item.data as CalendarEntry;
        const updatedEntry = {...entry, from: targetDate.start, to: targetDate.end};
        this.updateEntry(entry, updatedEntry);
        this.entryMoved.emit(updatedEntry);
    }

    private updateEntry(entry: CalendarEntry, update?: CalendarEntry): void {
        if (!entry.editable) {
            return;
        }

        this.calendar.updateEvent(entry, update);
    }

    private moveToZone(dropEvent: CdkDragDrop<CalendarResource, CalendarEntry>, targetDate: OmtDateRange, targetDay: CalendarDay, targetZone: CalendarZone): void {
        const entry = dropEvent.item.data as CalendarEntry;
        this.calendar.removeEvent(entry);

        const entryTimeSpan = {startHour: targetDate.start.getHours(), endHour: targetDate.end.getHours()};
        const startAndEnd = setStartAndEnd(targetDate.start, entryTimeSpan);

        const overlappingEntry = targetDay.events
            .filter((event) => event.editable)
            .find((event) => startAndEnd.start < event.from && startAndEnd.end >= event.from);

        if (overlappingEntry) {
            startAndEnd.end.setHours(overlappingEntry.from.getHours() - 1);
        }

        const updatedEntry = {...entry, ...{from: startAndEnd.start, to: startAndEnd.end, unitId: targetZone.unit.id}};

        // TODO - needs to be removed or implemented properly once decided
        // const blockingEntry = this.checkForBlockingEntry(targetDay, updatedEntry);
        // if (blockingEntry)
        // console.log('blocking entry!', blockingEntry);
        // TODO also check for blocking entries recursively
        // this.moveBlockingEntry(blockingEntry, entryTimeSpan);

        this.calendar.addEvent(updatedEntry, dropEvent.currentIndex);
        this.entryMoved.emit(updatedEntry);
    }

    private checkForBlockingEntry(targetDay: CalendarDay, updatedEntry: CalendarEntry): CalendarEntry | null {
        return targetDay.events.filter((event) => event.editable).find((event) => {
            const eventStartHour = event.from.getHours();
            const eventEndHour = event.to.getHours();
            const updatedEventStartHour = updatedEntry.from.getHours();
            const updatedEventEndHour = updatedEntry.to.getHours();
            const overlaps = (updatedEventStartHour === eventStartHour || updatedEventEndHour === eventEndHour)
                || (updatedEventStartHour > eventStartHour && updatedEventEndHour < eventEndHour)
                || (eventStartHour > updatedEventStartHour && eventEndHour < updatedEventEndHour);

            if (overlaps) {
                return event;
            }
        });
    }

    private moveBlockingEntry(entry: CalendarEntry, targetHours: { startHour: number; endHour: number }): void {
        const entryStartHour = entry.from.getHours();
        const entryEndHour = entry.to.getHours();
        let difference = 0;

        if (entryStartHour === targetHours.startHour) {
            difference = targetHours.endHour - targetHours.startHour;
        } else if (entryStartHour > targetHours.endHour) {
            difference = entryStartHour - targetHours.endHour;
        }

        const startAndEnd = setStartAndEnd(entry.from, {startHour: entryStartHour + difference, endHour: entryEndHour + difference});
        const updatedEntry = {...entry, from: startAndEnd.start, to: startAndEnd.end};
        this.updateEntry(entry, updatedEntry);
        this.entryMoved.emit(updatedEntry);
    }

    private addEntry(targetDate: OmtDateRange, dropEvent: CdkDragDrop<CalendarResource, CalendarEntry>, day: CalendarDay, zone: CalendarZone): CalendarEntry {
        const resource = dropEvent.item.data as CalendarResource;

        const newEntry: CalendarEntry = {
            from: targetDate.start,
            to: targetDate.end,
            resource,
            name: dropEvent.item.data.name,
            unitId: zone.unit.id,
            uniqueId: v4(),
            editable: true
        };

        this.calendar.removeResource(resource);
        this.calendar.addEvent(newEntry);
        this.entryAdded.emit(newEntry);

        return newEntry;
    }

    private removeEntry(entry: CalendarEntry): void {
        this.calendar.removeEvent(entry);
    }

    private setActiveHour(): void {
        const now = new Date().getHours();
        this.activeHour = this.displayHours.find((hour) => hour === now);

        const minute = new Date().getMinutes();
        const remainderUntilNextHour = (60 - minute) * 60 * 1000;

        this.subs.sink = interval(remainderUntilNextHour).subscribe(() => this.setActiveHour());
    }

    private setDisplayDate(): void {
        const week = this.viewDate.getWeekStartAndEnd();
        let displayDates = dateRange(week.start, week.end);

        if (!this._displayWeekEnds) {
            displayDates = filterWeekEnds(displayDates);
        }

        this.calendar.dates = [...displayDates];
    }

    private getNearestTimeSegment(timeSegments: Element[], {x}: { x: number }, useLeftEdge = false): HTMLElement {
        let nearestTimeSegment: HTMLElement;
        let currentDistance;

        timeSegments.forEach((timeSeg: HTMLElement) => {
            if (!nearestTimeSegment) {
                nearestTimeSegment = timeSeg;
            }

            const rect = timeSeg.getBoundingClientRect();
            const timeSegTargetCoordinate = useLeftEdge ? rect.left : rect.right;
            const nextDistance = Math.abs(timeSegTargetCoordinate - x);

            if (!currentDistance || nextDistance < currentDistance) {
                nearestTimeSegment = timeSeg;
                currentDistance = nextDistance;
            }
        });

        return nearestTimeSegment;
    }
}
