<div class="category" fxLayout="column">
  <h2 class="category-label mt-1">
    {{ title }}
  </h2>


  @if (isLoading) {
    <omt-spinner></omt-spinner>
  }

  <div #container (window:resize)="reCalcSize()">
    <mat-grid-list cdkDropListGroup [cols]="cols">
      @if (isUserBoard) {
        <mat-grid-tile [colspan]="1" [rowspan]="1" style="width: 210px; height: 210px">
          <div [routerLink]="'assigned-ticket-board'" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2rem" class="board-card">
            <mat-icon>assessment</mat-icon>
            <span class="text-center">{{ 'TICKET.ASSIGNED_TICKETS' | translate }}</span>
          </div>
        </mat-grid-tile>
      }

      @if (projectBoard && filter?.showProjectBoards) {
        @for (project of getDisplayedProjectBoards(); track project) {
          <mat-grid-tile [colspan]="1" [rowspan]="1" style="width: 210px; height: 210px">
            <div [routerLink]="['assigned-ticket-board/project/', project.id]" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2rem" class="board-card">
              <mat-icon>assessment</mat-icon>
              <span class="text-center">{{ project.name }} Tickets</span>
              <span class="text-center">{{ 'BOARD.PROJECT_BOARD' | translate }}</span>
              <span class="text-center">{{ project.customer?.name }}</span>
            </div>
          </mat-grid-tile>
        }
      }

      @for (board of boards; track board.id; let i = $index) {
        <mat-grid-tile
          cdkDropList
          [cdkDropListData]="i"
          [colspan]="1"
          [rowspan]="1"
          style="width: 210px; height: 210px"
        >
          <div cdkDrag [cdkDragData]="i" (cdkDragEntered)="entered($event)" (cdkDragDropped)="dropped()">
            <div
              [routerLink]="[board.id]"
              fxLayout="column"
              fxLayoutAlign="center center"
              fxLayoutGap="2rem"
              class="board-card"
              [style.background-image]="'url(' + board.boardImageUrl + ')'"
            >
              @if (!showImageTextOverlay(board)) {
                <mat-icon>assessment</mat-icon>
              }
              <span class="text-center" [class.image-text-overlay]="showImageTextOverlay(board)">{{ board.name }}</span>
              @if (board.projectId) {
                <span class="text-center" [class.image-text-overlay]="showImageTextOverlay(board)">{{ 'BOARD.PROJECT_BOARD' | translate }}</span>
                @if (board.project) {
                  <span class="text-center" [class.image-text-overlay]="showImageTextOverlay(board)">{{ board.project.name }}</span>
                }
                @if (board.project?.customer) {
                  <span class="text-center board-unit" [class.image-text-overlay]="board.boardImageUrl?.length > 4">
                    {{ board.project?.customer?.name }}
                  </span>
                }
              }
              @if (board.businessUnitId) {
                @if (board.businessUnit?.teamId) {
                  <span class="text-center" [class.image-text-overlay]="showImageTextOverlay(board)">Teamboard</span>
                } @else {
                  <span class="text-center" [class.image-text-overlay]="showImageTextOverlay(board)">Userboard</span>
                }
                <span class="text-center board-unit" [class.image-text-overlay]="showImageTextOverlay(board)">{{ getBusinessUnitName(board.businessUnitId) }}</span>
              }
            </div>
          </div>
        </mat-grid-tile>
      }

      <mat-grid-tile [colspan]="1" [rowspan]="1" style="width: 210px; height: 210px">
        <div (click)="openDialog(teamBoard, projectBoard)" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2rem" class="board-create-card">
          <button mat-fab>
            <i class="fas fa-plus"></i>
          </button>
          <h2>{{ 'BOARD.ADD_BOARD' | translate }}</h2>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
