import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CurrentTicketSwitcherComponent} from '@ticket/current-ticket-switcher/current-ticket-switcher.component';
import {TicketSwitchCardComponent} from '@ticket/current-ticket-switcher/ticket-switch-card/ticket-switch-card.component';
import {
    TicketSwitchCardPlaceholderComponent
} from '@ticket/current-ticket-switcher/ticket-switch-card-placeholder/ticket-switch-card-placeholder.component';
import {TicketSwitchStopDialogComponent} from '@ticket/current-ticket-switcher/ticket-switch-stop-dialog/ticket-switch-stop-dialog.component';
import {TicketSwitchDialogComponent} from '@ticket/current-ticket-switcher/ticket-switch-dialog/ticket-switch-dialog.component';
import {FlexModule} from '@ngbracket/ngx-layout';
import {TicketTypeModule} from '@ticket/ticket-type/ticket-type.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {SearchInputModule} from '@libs/ui/src/components/search-input/search-input.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CoreModule} from '@core/core.module';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {DialogHostModule} from '@libs/ui/src/components/dialog-host/dialog-host.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {
    FromToTimePickerComponent
} from '@ticket/current-ticket-switcher/ticket-switch-stop-dialog/from-to-time-picker/from-to-time-picker.component';
import {TimeSelectModule} from '@core/components/time-select/time-select.module';
import {TranslateModule} from '@ngx-translate/core';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
    declarations: [
        CurrentTicketSwitcherComponent,
        TicketSwitchCardComponent,
        TicketSwitchCardPlaceholderComponent,
        TicketSwitchDialogComponent,
        TicketSwitchStopDialogComponent,
        FromToTimePickerComponent
    ],
    exports: [CurrentTicketSwitcherComponent],
    imports: [
        CommonModule,
        FlexModule,
        TicketTypeModule,
        MatButtonModule,
        MatTooltipModule,
        SearchInputModule,
        ScrollingModule,
        CoreModule,
        MatCardModule,
        DialogHostModule,
        NgSelectModule,
        FormsModule,
        MatInputModule,
        MatCheckboxModule,
        TimeSelectModule,
        TranslateModule,
        CdkConnectedOverlay,
        CdkOverlayOrigin,
        MatDividerModule
    ]
})
export class CurrentTicketModule {
}
