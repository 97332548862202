import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreModule} from '@core/core.module';
import {FuseDirectivesModule} from '@fuse/directives/directives';
import {FlexModule} from '@ngbracket/ngx-layout';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {SharedModule} from '@shared/shared.module';
import {DatePickerModule} from '@core/components/datepicker/date-picker.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {FormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {ResizableModule} from 'angular-resizable-element';
import {ResourceSchedulerCalendarComponent} from './resource-scheduler-calendar.component';
import {SearchInputModule} from '@libs/ui/src/components/search-input/search-input.module';
import {CalendarEntryWidthPipe} from './calendar-entry-width.pipe';
import {CalendarEntryPositionPipe} from './calendar-entry-position.pipe';
import {SimpleCardModule} from '@libs/ui/src/components/simple-card/simple-card.module';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';


@NgModule({
    declarations: [
        ResourceSchedulerCalendarComponent,
        CalendarEntryWidthPipe,
        CalendarEntryPositionPipe
    ],
    exports: [
        ResourceSchedulerCalendarComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        FuseDirectivesModule,
        FlexModule,
        ScrollingModule,
        SharedModule,
        DatePickerModule,
        MatButtonModule,
        FormsModule,
        DragDropModule,
        MatTooltipModule,
        ResizableModule,
        SearchInputModule,
        SimpleCardModule,
        MatCardModule
    ]
})
export class TimeBoardWeekSchedulerModule {
}
