import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Observable, timer} from 'rxjs';
import {DialogService} from '@core/services/global/dialog.service';
import {SubSink} from 'subsink';
import {MatLegacyTooltip as MatTooltip} from '@angular/material/legacy-tooltip';
import {UserService} from '@core/services/user.service';
import {CurrentTicketInfoModel, TicketModel} from '@ticket/models';
import {TicketHttpService} from '@ticket/ticket-http.service';
import {CurrentTicketService} from '@ticket/current-ticket-switcher/current-ticket.service';
import {filter, switchMap, tap} from 'rxjs/operators';
import {TicketSwitchStopDialogData} from '@ticket/current-ticket-switcher/ticket-switch-stop-dialog/ticket-switch-stop-dialog-data';
import {FavoriteService} from '@core/services/global/favorite.service';

@Component({
    selector: 'omt-current-ticket-switcher',
    templateUrl: './current-ticket-switcher.component.html',
    styleUrls: ['./current-ticket-switcher.component.scss']
})
export class CurrentTicketSwitcherComponent implements OnInit, OnDestroy {
    @ViewChild('switcherTip')
    set tooltip(el: MatTooltip) {
        this._tooltip = el;
    }

    private readonly _lsKey = 'showTicketSwitcherTip';
    private subs = new SubSink();
    private searchString: string;
    private _tooltip: MatTooltip;
    private currentTicketInfoModel: CurrentTicketInfoModel;

    selectedTicket: TicketModel;
    displayTickets: TicketModel[] = [];
    private favoriteTickets: TicketModel[] = [];
    private currentTickets: TicketModel[] = [];
    userId: number;
    durationText: string;
    expanded = false;
    tooltipDisabled = false;

    constructor(private readonly userService: UserService,
                private readonly ticketService: TicketHttpService,
                private readonly currentTicketService: CurrentTicketService,
                private readonly dialogService: DialogService,
                private readonly favoriteService: FavoriteService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.loadData();
        this.subs.sink = this.currentTicketService.currentTicketMaximumDurationReached.subscribe(() => this.openDialog());
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ UI
    // -----------------------------------------------------------------------------------------------------

    private displaySwitcherTooltip(): void {
        setTimeout(() => this._tooltip?.show(), 250);

        setTimeout(() => {
            this._tooltip?.hide();
            localStorage.setItem(this._lsKey, 'false');
        }, 5000);
    }

    private shouldShowTooltip(): boolean {
        return (this.displayTickets ? this.displayTickets.length === 0 : false) && (!localStorage.getItem(this._lsKey) || localStorage.getItem(this._lsKey) === 'true');
    }

    private filter(filterText: string): number {
        const filtered = [...this.currentTickets, ...this.favoriteTickets].filter((x) => x.summary?.toLowerCase().includes(filterText));
        this.displayTickets = [...filtered];
        return filtered.length;
    }

    searchEntered(event: string): void {
        this.searchString = event?.toLowerCase();
        this.filter(this.searchString);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Business Logic
    // -----------------------------------------------------------------------------------------------------

    private loadData(): void {
        this.subs.sink = combineLatest([this.loadCurrentTicketInfo(), this.loadFavoriteTickets()])
            .pipe(filter(([val1, val2]) => val1 != null && val2 != null),
                tap(() => this.setDisplayTickets())).subscribe();

        this.timeInterval();
    }

    private loadFavoriteTickets(): Observable<TicketModel[]> {
        return this.favoriteService.getFavoriteTickets().pipe(tap((tickets) => this.favoriteTickets = [...tickets]));
    }

    private loadCurrentTicketInfo(): Observable<CurrentTicketInfoModel> {
        return this.currentTicketService.currentTicketInfo
            .pipe(tap((currentTicketInfoModel) => this.currentTicketInfoModel = currentTicketInfoModel));
    }

    private setDisplayTickets(): void {
        this.userId = this.currentTicketInfoModel.userId;

        if (this.currentTicketInfoModel.currentTickets) {
            this.currentTickets = [...this.currentTicketInfoModel.currentTickets];
        }

        this.selectedTicket = this.currentTicketInfoModel.currentTicket;
        this.displayTickets = [...this.currentTickets, ...this.favoriteTickets];
        this.tooltipDisabled = !this.shouldShowTooltip();

        if (!this.tooltipDisabled) {
            this.displaySwitcherTooltip();
        } else {
            this.tooltipDisabled = this.displayTickets?.length > 0;
        }
    }

    private timeInterval(): void {
        this.subs.sink = timer(1500, 5000).subscribe(() => {
            if (!this.selectedTicket) {
                return;
            }

            this.durationText = this.currentTicketService.getCurrentTicketWorkingTimeText(this.selectedTicket.id);
        });
    }

    changeTicket(ticket: TicketModel): void {
        this.subs.sink = this.currentTicketService.switchTicket(ticket.id).subscribe(() => {
            this.timeInterval();
            this.expanded = false;
        });
    }

    openDialog(): void {
        const data = new TicketSwitchStopDialogData();
        data.ticketRemainingDuration = this.currentTicketService.currentTicketInfo.value.remainingDuration;
        data.ticketId = this.selectedTicket.id;
        data.ticketName = this.selectedTicket.summary;

        const dialogRef = this.dialogService.openTicketSwitchStopDialog(data);

        this.subs.sink = dialogRef.afterClosed()
            .pipe(switchMap(() => this.currentTicketService.currentTicketInfo), tap(() => this.setDisplayTickets()))
            .subscribe((currentTicketInfoModel) => this.currentTicketInfoModel = currentTicketInfoModel);
    }
}
