@if (board) {
  @switch (board.boardTypeId) {
    @case (boardTypeEnum.FavoriteBoard) {
      <omt-list-board [board]="board" (reloadBoard)="loadBoard()"></omt-list-board>
    }
    @case (boardTypeEnum.WorkFlowBoard) {
      <omt-workflow-board [board]="board" [(hideCompleted)]="hideCompleted" (hideCompletedChange)="loadBoard()"></omt-workflow-board>
    }
    @case (boardTypeEnum.TimeBoard) {
      <omt-time-board [boardTransferModel]="board" (reloadBoard)="loadBoard()"></omt-time-board>
    }
  }
}

@if (isLoading) {
  <omt-spinner></omt-spinner>
}
