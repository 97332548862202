<mat-sidenav-container style="height: 100%" [ngStyle]="{'background-color': board.secondAccentColor}">
  <div class="page-layout simple redesign" id="tickets" (window:resize)="resize()">
    <omt-board-header #headerComponent [boardTransferModel]="board" (settingsToggled)="settingsSidenav.toggle()">
      <ng-container actions-left>
        <div></div>
      </ng-container>
      <ng-container actions-right>
        @if (board.boardUnit === boardUnitEnum.Teamboard) {
          <button mat-icon-button (click)="toggleScheduler()"
                  aria-label="Scheduler" [matTooltip]="'BOARD.SCHEDULER' | translate">
            <mat-icon [ngStyle]="{'color': board.mainFontColor}">schedule</mat-icon>
          </button>
        } @else {
          <div></div>
        }
      </ng-container>
    </omt-board-header>


    <div class="content-spacer p-0">
      @if (!showScheduler) {
        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          [ngClass]="{'of-scroll': isMobileDevice}"
          cdkScrollable
          class="pt-0 p-1"
          fusePerfectScrollbar
        >
          <div fxLayout="row" cdkDropList cdkDropListOrientation="horizontal" cdkDropListLockAxis="x" (cdkDropListDropped)="dropState($event)" [cdkDropListData]="states">
            @for (state of states; track state.id) {
              <omt-board-state
                cdkDrag
                [cdkDragDisabled]="isMobileDevice"
                (stateDeleted)="removeState(state.id)"
                [state]="state"
                [boardId]="board.id"
                [fullHeight]="fullHeight"
                [backColor]="board.secondColor"
                [fontColor]="board.secondFontColor"
                [@animate]="{value: '*', params: getDuration(state)}"
                style="margin-top: 7px"
              >
                <div class="ticket-state-drop-placeholder" *cdkDragPlaceholder></div>
                <div
                  [id]="state.id.toString()"
                  cdkDropList
                  [cdkDropListConnectedTo]="states | stateId"
                  [cdkDropListData]="state.boardTickets"
                  (cdkDropListDropped)="dropTicket(state, $event)"
                  fxLayout="column"
                  fxLayoutAlign="start center"
                  fxLayoutGap="1rem"
                >
                  @for (ticket of state.boardTickets; track ticket.id) {
                    <omt-ticket-card
                      [boardTicket]="ticket"
                      [fontColor]="board.thirdFontColor"
                      [backColor]="board.thirdColor"
                      [accentColor]="board.thirdAccentColor"
                      cdkDrag
                    >
                      <div class="ticket-card-drop-placeholder" *cdkDragPlaceholder></div>
                    </omt-ticket-card>
                  }
                </div>
              </omt-board-state>
            }
          </div>
          <!--          Add List Card -->
          <mat-card class="w-350 add-card box-shadow" style="margin-top: 7px" [@animate]="{value: '*', params: getLastDuration()}">
            <div fxLayout="column" fxLayoutGap="1rem">
              <input [(ngModel)]="newBoardState.name" (keyup.enter)="saveNewState()" [placeholder]="'BOARD.LIST_NAME' | translate" class="redesign w-100-p" type="text"/>
              <button mat-raised-button (click)="saveNewState()" color="primary" class="w-150">{{ 'BOARD.ADD_LIST' | translate }}</button>
            </div>
          </mat-card>
        </div>
      } @else {
        <omt-time-board-user-scheduler [board]="board"></omt-time-board-user-scheduler>
      }
    </div>
  </div>

  <mat-sidenav #settingsSidenav position="end">
    <omt-board-sidenav [boardTransferModel]="board" [(hideCompleted)]="hideCompleted" (hideCompletedChange)="hideCompletedChange.emit($event)"></omt-board-sidenav>
  </mat-sidenav>
</mat-sidenav-container>
