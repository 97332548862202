import {Pipe, PipeTransform} from '@angular/core';
import {CalendarEntry} from '@core/components/resource-scheduler-calendar/calendar-entry';

@Pipe({
    name: 'calendarEntryPosition',
    pure: true
})
export class CalendarEntryPositionPipe implements PipeTransform {
    transform(hourWidth: number, startHour: number, entry: CalendarEntry): number {
        const offset = 0.1;
        const diff = entry.from.getHours() - startHour;
        const hours = diff > 0 ? diff : 0;
        return hourWidth * hours + offset;
    }
}
