<mwl-calendar-month-view
  [viewDate]="tbs.viewDate"
  [events]="tbs.events"
  [refresh]="tbs.refresh"
  [activeDayIsOpen]="activeDayIsOpen"
  (eventTimesChanged)="tbs.eventTimesChanged($event)"
  (dayClicked)="dayClicked($event.day)"
  [openDayEventsTemplate]="test2"
>
</mwl-calendar-month-view>

<ng-template #test> Test</ng-template>

<ng-template #test2 let-events="events" let-eventClicked="eventClicked" let-isOpen="isOpen" let-trackByEventId="trackByEventId" let-validateDrag="validateDrag">
  @if (isOpen) {
    <div class="cal-open-day-events" role="application">
      @for (event of events; track trackByEventId($index, event)) {
        <div
          [ngClass]="event?.cssClass"
          mwlDraggable
          [class.cal-draggable]="event.draggable"
          dragActiveClass="cal-drag-active"
          [dropData]="{event: event}"
          [dragAxis]="{x: event.draggable, y: event.draggable}"
          [validateDrag]="validateDrag"
        >
          <span class="cal-event" [ngStyle]="{backgroundColor: event.color?.primary}"></span>
          <span> {{ event.meta.dateFrom | timeFormat }} - {{ event.meta.dateTo | timeFormat }}</span>
          <img [src]="event.meta.ticket.assigneeAvatar" class="cursor-pointer border-radius-50 calender-mont-view-img" [matTooltip]="event.meta.ticket.assigneeName"/>
          <mwl-calendar-event-title
            [event]="event"
            view="month"
            (mwlClick)="eventClicked.emit({event: event, sourceEvent: $event})"
            (mwlKeydownEnter)="eventClicked.emit({event: event, sourceEvent: $event})"
            tabindex="0"
            (click)="tbs.openTicket(event.meta.ticket.key)"
          >
          </mwl-calendar-event-title>
        </div>
      }
    </div>
  }
</ng-template>
