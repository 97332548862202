import {Injectable} from '@angular/core';
import {BoardTypeEnum} from '@core/enums/board-type.enum';

@Injectable({
    providedIn: 'root'
})
export class BoardService {
    isListBoard({boardTypeId}: { boardTypeId: number }): boolean {
        return BoardTypeEnum.FavoriteBoard === boardTypeId;
    }
}
