import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {timer} from 'rxjs';
import {Location} from '@angular/common';

@Component({
    selector: 'omt-assigned-ticket-board-header',
    templateUrl: './assigned-ticket-board-header.component.html',
    styleUrls: ['./assigned-ticket-board-header.component.scss']
})
export class AssignedTicketBoardHeaderComponent implements OnInit {
    @Input() title: string;
    @Output() settingsToggled = new EventEmitter();
    fullHeight = 0;

    constructor(private readonly el: ElementRef, private readonly location: Location) {
    }

    ngOnInit(): void {
        this.getFullHeight();
    }

    getFullHeight(): number {

        if (this.el.nativeElement.offsetHeight === 0) {
            timer(50).subscribe((x) => {
                this.getFullHeight();
            });
            return 0;
        } else {
            return this.fullHeight = this.el.nativeElement.offsetHeight;
        }

    }

    navigateBack(): void {
        this.location.back();
    }

}
