<div class="collection-page" id="board-selection-splash" fxLayout="column" fxLayoutGap="15px">
  <div class="w-100-p board-select-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="collection-page-headline">
      <i class="fas fa-thumbtack text-white"></i>
      <h2>Boards</h2>
    </div>

    <omt-board-select-filter class="board-filter" [(filter)]="filter"></omt-board-select-filter>
  </div>

  @if (filter.groupByType) {
    <div fxLayout="column" fxLayoutAlign="start center">
      @if (filter.showUserBoards) {
        <omt-board-select-row [boards]="userBoards | boardSelectFilter: filter" [title]="'BOARD.USER_BOARDS' | translate"></omt-board-select-row>
      }
      @if (filter.showTeamBoards) {
        <omt-board-select-row
          [boards]="teamBoards | boardSelectFilter: filter"
          [title]="'BOARD.TEAM_BOARDS' | translate"
          [teamBoard]="true"
        ></omt-board-select-row>
      }
      @if (filter.showProjectBoards) {
        <omt-board-select-row
          [boards]="projectBoards | boardSelectFilter: filter"
          [title]="'BOARD.PROJECT_BOARDS' | translate"
          [filter]="filter"
          [projectBoard]="true"
        ></omt-board-select-row>
      }
    </div>
  } @else {
    <omt-board-select-row [boards]="allBoards | boardSelectFilter: filter" [title]="'Boards'" [projectBoard]="true" [filter]="filter"></omt-board-select-row>
  }

</div>
