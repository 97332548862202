import {CalendarUnit} from '@core/components/resource-scheduler-calendar/calendar-unit';
import {CalendarResource} from '@core/components/resource-scheduler-calendar/calendar-resource';
import {CalendarEntry} from '@core/components/resource-scheduler-calendar/calendar-entry';
import {CalendarZone} from '@core/components/resource-scheduler-calendar/calendar-zone';
import {datesAreOnSameDay} from '@core/utils/date-utils';
import {CalendarDay} from '@core/components/resource-scheduler-calendar/calendar-day';

export class ResourceCalendar {
    constructor(dates: Date[], units: CalendarUnit[], displayHours: number[], resources?: CalendarResource[], entries?: CalendarEntry[]) {
        this._dates = [...dates];
        this._units = [...units];
        this._displayHours = [...displayHours];

        this.render();

        if (resources) {
            this.resources = [...resources];
        }

        if (entries) {
            this.entries = [...entries];
        }
    }

    private _dates: Date[];

    get dates(): Date[] {
        return this._dates;
    }

    set dates(newDates: Date[]) {
        this._dates = [...newDates];
        this.render();
    }

    private _units: CalendarUnit[];

    get units(): CalendarUnit[] {
        return this._units;
    }

    set units(newUnits: CalendarUnit[]) {
        if (newUnits == null) {
            return;
        }

        this._units = [...newUnits];
        this.render();
    }

    private _filter: string;

    set filter(filter: string) {
        this._filter = filter;
    }

    private _resources: CalendarResource[] = [];

    get resources(): CalendarResource[] {
        return this._resources.filter((resource) => this._filter == null || resource.name.toLowerCase().includes(this._filter.toLowerCase()));
    }

    set resources(calendarResources: CalendarResource[]) {
        if (calendarResources == null) {
            return;
        }

        this._resources = [...calendarResources];
    }

    private _zones: CalendarZone[];

    get zones(): CalendarZone[] {
        return this._zones;
    }

    private _entries: CalendarEntry[];

    set entries(entries: CalendarEntry[]) {
        if (entries == null) {
            return;
        }

        this._entries = [...entries];
        this.assignEntries(entries);
    }

    private _displayHours: number[];

    set displayHours(hours: number[]) {
        this._displayHours = hours;
        this.zones.forEach((zone) => zone.days.forEach((day) => day.displayHours = hours));
    }

    removeEvent(calendarEntry: CalendarEntry): void {
        this._entries = this._entries.filter((entry) => entry !== calendarEntry);
        const targetDay = this.findCurrentDay(calendarEntry);
        targetDay.events = targetDay.events.filter((event) => event !== calendarEntry);
    }

    addEvent(event: CalendarEntry, index?: number): void {
        const targetDay = this.findCurrentDay(event);
        this._entries.push(event);

        if (!targetDay.events) {
            targetDay.events = [];
        }

        if (targetDay.events.length === 0) {
            targetDay.events = [event];
            return;
        }

        if (index) {
            targetDay.events.splice(index, 0, event);
            return;
        }

        targetDay.events = [...targetDay.events, event];
    }

    updateEvent(entry: CalendarEntry, update?: CalendarEntry): void {
        const targetDay = this.findCurrentDay(entry);
        const dayEventIndex = targetDay.events.indexOf(entry);
        const mainIndex = this._entries.indexOf(entry);

        if (dayEventIndex == null) {
            console.error('Could not find entry');
            return;
        }

        const newVal = update ? {...update} : {...entry};
        this._entries[mainIndex] = newVal;
        targetDay.events[dayEventIndex] = newVal;
    }

    addResource(resource: CalendarResource, index: number): void {
        const newRes = [...this.resources];
        newRes.splice(index, 0, resource);
        this._resources = [...newRes];
    }

    removeResource(resource: CalendarResource): void {
        const filteredResources = this._resources.filter((x) => x !== resource);
        this.resources = [...filteredResources];
    }

    private findCurrentDay(entry: CalendarEntry): CalendarDay {
        const currentZone = this.zones.find((zone) => zone.unit.id === entry.unitId);
        return currentZone.days.find((day) => datesAreOnSameDay(day.date, entry.from));
    }

    private assignEntries(entries: CalendarEntry[]): void {
        this.zones.forEach((zone) =>
            zone.days.forEach((day) =>
                day.events = entries.filter((entry) => entry.unitId === zone.unit.id && datesAreOnSameDay(entry.from, day.date))));
    }

    private render(): void {
        const zones = [];

        this.units.forEach((unit) => {
            const newZone = new CalendarZone(this.dates, unit);
            newZone.days.forEach((day) => day.displayHours = this._displayHours);
            zones.push(newZone);
        });

        this._zones = [...zones];
        this.assignEntries(this._entries ?? []);
    }
}
