import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserModel} from '@core/models/user/user.model';
import {CustomerModel} from '@customer/models';
import {ProjectModel} from '@app/project-management/models';

@Component({
    selector: 'omt-assigned-ticket-side-nav',
    templateUrl: './assigned-ticket-side-nav.component.html',
    styleUrls: ['./assigned-ticket-side-nav.component.scss']
})
export class AssignedTicketSideNavComponent {
    @Input() customers: CustomerModel[];

    // can't filter by business units
    // should be all business units used by displayed tickets
    @Input() users: UserModel[];
    @Input() projects: ProjectModel[];

    @Input() customerFilter: number;
    @Output() customerFilterChange = new EventEmitter<number>();

    @Input() projectFilter: number;
    @Output() projectFilterChange = new EventEmitter<number>();

    @Input() userFilter: number;
    @Output() userFilterChange = new EventEmitter<number>();

    @Input() mode = 'unset';

    constructor() {
    }

    updateCustomer(): void {
        this.customerFilterChange.emit(this.customerFilter);

    }

    updateProject(): void {
        this.projectFilterChange.emit(this.projectFilter);

    }

    updateUser(): void {
        this.userFilterChange.emit(this.userFilter);
    }
}
