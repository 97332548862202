import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {ConfirmDialogComponent} from '@shared/components/delete-dialog/confirm-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {TextInputDialogComponent, TextInputDialogComponentData} from '@shared/components/text-input-dialog/text-input-dialog.component';
import {DialogService} from '@core/services/global/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {SubSink} from 'subsink';
import {SelectionModel} from '@angular/cdk/collections';
import {BoardService} from '@boards/board.service';
import {BoardHttpService} from '@boards/board-http.service';
import {BoardTransferModel} from '@boards/models';
import {TicketListItem} from '@ticket/models';
import {ConfirmDialogComponentData} from '@shared/components/delete-dialog/confirm-dialog-component-data';
import {switchMap} from 'rxjs/operators';

@Component({
    selector: 'omt-board-sidenav',
    templateUrl: './board-sidenav.component.html',
    styleUrls: ['./board-sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class BoardSidenavComponent implements OnDestroy {
    @Input() boardTransferModel: BoardTransferModel;
    @Input() hideCompleted: boolean;
    @Output() hideCompletedChange = new EventEmitter<boolean>();
    @Output() reloadTickets = new EventEmitter();


    static readonly hideCompletedKey = 'HideCompletedBoardTickets';
    private subs = new SubSink();
    view: string;

    constructor(private readonly boardHttpService: BoardHttpService,
                private readonly boardService: BoardService,
                private readonly dialog: MatDialog,
                private readonly router: Router,
                private readonly translateService: TranslateService,
                private readonly dialogService: DialogService,
                private readonly cd: ChangeDetectorRef
    ) {
        // Set the defaults
        this.view = 'main';
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    changeCompletedFilter(): void {
        localStorage.setItem(BoardSidenavComponent.hideCompletedKey, this.hideCompleted.toString());
        this.hideCompletedChange.emit(this.hideCompleted);
    }

    openDeleteDialog(): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '420px',
            position: {top: '15%'},
            data: new ConfirmDialogComponentData(this.translateService.instant('BOARD.DELETE_BOARD'), this.translateService.instant('BOARD.REALLY_DELETE'))
        });

        this.subs.sink = dialogRef.componentInstance.deleteClicked.subscribe((confirmed) => {
            if (confirmed) {
                this.deleteBoard();
            }
        });
    }

    deleteBoard(): void {
        this.subs.sink = this.boardHttpService.deleteBoard(this.boardTransferModel.id).subscribe(() => this.router.navigate(['boards']).then());
    }

    openRenameDialog(): void {
        const dialogRef = this.dialog.open(TextInputDialogComponent, {
            width: '420px',
            position: {top: '15%'},
            data: new TextInputDialogComponentData(this.translateService.instant('BOARD.RENAME'), this.translateService.instant('BOARD.REALLY_RENAME'), this.boardTransferModel.name)
        });

        this.subs.sink = dialogRef.componentInstance.saveClicked
            .pipe(switchMap((newName) => this.boardHttpService.updateBoardName(this.boardTransferModel.id, newName)))
            .subscribe((data) => {
                this.boardTransferModel = {...this.boardTransferModel, id: data.id, name: data.name};
                this.cd.markForCheck();
            });
    }

    openCopyDialog(): void {
        const dialogRef = this.dialog.open(TextInputDialogComponent, {
            width: '420px',
            position: {top: '15%'},
            data: new TextInputDialogComponentData(this.translateService.instant('BOARD.COPY'), this.translateService.instant('BOARD.REALLY_COPY'), this.boardTransferModel.name)
        });

        this.subs.sink = dialogRef.componentInstance.saveClicked
            .pipe(switchMap((boardName) => this.boardHttpService.copyBoard(this.boardTransferModel.id, boardName)))
            .subscribe();
    }

    setBoardColor(color: string, colorName: string): void {
        if (!color || !colorName) {
            return;
        }

        this.subs.sink = this.boardHttpService.updateBoardColor(this.boardTransferModel.id, colorName, color).subscribe();
    }

    uploadBoardImage(file: File): void {
        this.subs.sink = this.boardHttpService.saveBoardImage(file, this.boardTransferModel.id).subscribe((value) => {
            this.boardTransferModel = {...this.boardTransferModel, boardImageUrl: value};
            this.cd.markForCheck();
        });
    }

    openTicketDialog(): void {
        const dialogRef = this.dialogService.ticketSelectionDialog(this.boardTransferModel.id, null, this.boardTransferModel.boardTickets.map((x) => x.ticket.id));

        this.subs.sink = dialogRef.componentInstance.selectionChanged.subscribe((x: SelectionModel<TicketListItem>) => {
            const ticketIds = x.selected.map((z) => z.id);
            this.subs.sink = this.boardHttpService.createTicketLinks(ticketIds, this.boardTransferModel.id).subscribe(() => this.reloadTickets.emit());
        });
    }

    isListBoard = (): boolean => this.boardService.isListBoard(this.boardTransferModel);
}
