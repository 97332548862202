import {CalendarUnit} from '@core/components/resource-scheduler-calendar/calendar-unit';
import {CalendarDay} from '@core/components/resource-scheduler-calendar/calendar-day';

export class CalendarZone {
    constructor(dates: Date[], unit: CalendarUnit) {
        this._unit = unit;
        const days = [];

        dates.forEach((date) => {
            const day = new CalendarDay(date);
            days.push(day);
        });

        this.days = [...days];
    }

    days: CalendarDay[];
    private readonly _unit: CalendarUnit;

    get unit(): CalendarUnit {
        return this._unit;
    }
}
