<div id="header-small" fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-icon-button aria-label="Settings" [matTooltip]="'BOARD.BACK_TO_BOARDS' | translate" (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div></div>

  <span id="board-title">{{ title }}</span>

  <div>
    <ng-content></ng-content>
  </div>

  <button mat-icon-button (click)="settingsToggled.emit()" aria-label="Settings" [matTooltip]="'BOARD.SETTINGS' | translate">
    <mat-icon>settings</mat-icon>
  </button>
</div>
