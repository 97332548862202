<div fxLayout="row" fxLayoutAlign="space-between center" class="current-ticket-wrapper">
  <div (click)="expanded = !expanded" cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="current-ticket-container" fxLayout="column" fxLayoutAlign="center">
    @if (selectedTicket) {
      <div fxLayout="row" fxLayoutAlign="start center" class="active-ticket">
        <omt-ticket-type displayMode="icon-only" [ticketType]="selectedTicket.ticketType" style="line-height: 24px"></omt-ticket-type>
        <span class="ticket-title">{{ selectedTicket.summary }}</span>
      </div>
    } @else {
      <button
        mat-icon-button
        #switcherTip="matTooltip"
        [matTooltipDisabled]="tooltipDisabled"
        [matTooltip]="'WORK_LOG.SWITCHER_INFOTEXT' | translate">
        <i class="fas fa-play ticket-play"></i>
      </button>
    }

  </div>

  @if (selectedTicket && selectedTicket.id) {
    <div class="duration-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1rem">
      <strong>
        {{ durationText }}
      </strong>
      <i class="fas fa-pause" (click)="openDialog()" [matTooltip]="'WORK_LOG.PAUSE' | translate"></i>
      <i class="fas fa-stop" (click)="openDialog()" [matTooltip]="'WORK_LOG.STOP' | translate"></i>
    </div>
  }
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="expanded" [cdkConnectedOverlayOrigin]="trigger" (overlayOutsideClick)="expanded = false" cdkConnectedOverlayPush>
  <mat-card id="switcher-list" fxLayout="column" class="fav-ticket-list mat-elevation-z18 p-0">
    <div class="p-1">
      <omt-search-input displayMode="full" (searchChanged)="searchEntered($event)"></omt-search-input>
    </div>
    <cdk-virtual-scroll-viewport minBufferPx="325" maxBufferPx="390" itemSize="65" class="switcher-viewport" orientation="vertical" tabindex="-1">
      <ng-container *cdkVirtualFor="let ticket of displayTickets; trackBy: 'id' | trackByProperty">
        <omt-ticket-switch-card
          [ticket]="ticket"
          (click)="changeTicket(ticket)"
        ></omt-ticket-switch-card>
        <mat-divider></mat-divider>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </mat-card>
</ng-template>
