<div fxLayout="row" fxLayoutAlign="space-between center" class="fav-ticket">
  <div fxFlex="80" fxLayout="column">
    <!--  upper row - icon + ticket-key-->
    <div (click)="$event.stopPropagation(); openTicket(ticket)" fxLayout="row" fxLayoutAlign="start center" class="ticket-key">
      <i class="fas fa-external-link-square-alt"></i>
      <span>{{ ticket.ticketKey }}</span>
    </div>

    <!-- lower row - type icon + ticket title-->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <omt-ticket-type displayMode="icon-only" [ticketType]="ticket.ticketType"></omt-ticket-type>
      <span class="ticket-title">{{ ticket.summary }}</span>
    </div>
  </div>

  <img [src]="ticket.assigneeAvatar" class="avatar" [matTooltip]="ticket.assignee?.displayName" alt="Avatar"/>
</div>
