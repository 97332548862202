import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@ngbracket/ngx-layout';
import {FormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {ProjectModule} from '@app/management/tickets/project/project.module';

import {NgSelectModule} from '@ng-select/ng-select';
import {FuseDirectivesModule} from '@fuse/directives/directives';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {TranslateModule} from '@ngx-translate/core';
import {BoardActionMenuComponent} from './components/board-action-menu/board-action-menu.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import {MatRippleModule} from '@angular/material/core';
import {FuseSharedModule} from '@fuse/shared.module';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatGridListModule} from '@angular/material/grid-list';
import {TimeBoardComponent} from './components/time-board/time-board.component';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {EditTimeBoardTicketDialogComponent} from './components/time-board/edit-time-board-ticket-dialog/edit-time-board-ticket-dialog.component';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {TimeBoardWeekComponent} from './components/time-board/time-board-week/time-board-week.component';
import {TimeBoardMonthComponent} from './components/time-board/time-board-month/time-board-month.component';
import {TimeBoardDayComponent} from './components/time-board/time-board-day/time-board-day.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {AssignedTicketBoardComponent} from './components/assigned-ticket-board/assigned-ticket-board.component';
import {ActionlessBoardStateComponent} from './components/assigned-ticket-board/actionless-board-state/actionless-board-state.component';
import {
    AssignedTicketBoardHeaderComponent
} from './components/assigned-ticket-board/assigned-ticket-board-header/assigned-ticket-board-header.component';
import {AssignedTicketSideNavComponent} from './components/assigned-ticket-board/assigned-ticket-side-nav/assigned-ticket-side-nav.component';
import {BoardSelectComponent} from '@boards/components/board-select/board-select.component';
import {BoardSidenavComponent} from '@boards/components/sidenavs/board-sidenav.component';
import {BoardSidenavColorOptionComponent} from '@boards/components/sidenavs/board-sidenav-color-option/board-sidenav-color-option.component';
import {SidenavColorSelectorComponent} from '@boards/components/sidenavs/board-color-selector/board-color-selector.component';
import {BoardsRoutingModule} from '@boards/boards-routing.module';
import {ListBoardComponent} from '@boards/components/list-board/list-board.component';
import {WorkFlowBoardComponent} from '@boards/components/workflow-board/work-flow-board.component';
import {MotherBoardComponent} from '@boards/components/mother-board/mother-board.component';
import {BoardHeaderComponent} from '@boards/components/board-header/board-header.component';
import {BoardSelectRowComponent} from '@boards/components/board-select/board-select-row/board-select-row.component';
import {BoardCreateDialogComponent} from '@boards/components/board-create-dialog/board-create-dialog.component';
import {CoreModule} from '@core/core.module';
import {TimeBoardUserSchedulerComponent} from '@boards/components/time-board/time-board-user-scheduler/time-board-user-scheduler.component';
import {TicketBadgeFilterDialogComponent} from '@ticket/ticket-badge-filter-dialog/ticket-badge-filter-dialog.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {BoardSelectFilterComponent} from './components/board-select/board-select-filter/board-select-filter.component';
import {BoardSelectFilterPipe} from './components/board-select/board-select-filter/board-select-filter.pipe';
import {SharedModule} from '@shared/shared.module';
import {DatePickerModule} from '@core/components/datepicker/date-picker.module';
import {TimeSelectModule} from '@core/components/time-select/time-select.module';
import {HeaderModule} from '@core/components/header/header.module';
import {ImageCropperModule} from '@core/components/image-cropper/image-cropper.module';
import {SearchInputModule} from '@libs/ui/src/components/search-input/search-input.module';
import {SpinnerModule} from '@libs/ui/src/components/spinner/spinner.module';
import {TicketTypeModule} from '@ticket/ticket-type/ticket-type.module';
import {BoardStateComponent} from '@boards/components/workflow-board/board-state/board-state.component';
import {TicketCardComponent} from '@boards/components/workflow-board/ticket-card/ticket-card.component';
import {TicketStateDropdownModule} from '@ticket/ticket-state-dropdown/ticket-state-dropdown.module';
import {AvatarModule} from '@libs/ui/src/components/avatar/avatar.module';
import {TimeBoardWeekSchedulerModule} from '@core/components/resource-scheduler-calendar/resource-scheduler-calendar.module';
import {HelpIconModule} from '@libs/ui/src/components/help-icon/help-icon.module';
import {DialogHostModule} from '@libs/ui/src/components/dialog-host/dialog-host.module';
import {CurrentTicketModule} from '@ticket/current-ticket-switcher/current-ticket.module';
import {BusinessUnitDropdownModule} from '@shared/components/dropdowns/business-unit-dropdown/business-unit-dropdown.module';
import {ProjectDropdownModule} from '@app/project-management/project-dropdown/project-dropdown.module';
import {BookmarkButtonModule} from '@libs/ui/src';
import {DeleteIconModule} from '@core/components/delete-icon/delete-icon.module';
import {FavoriteButtonComponent} from '@core/components/favorites/favorite-button.component';
import {ButtonComponent} from '@libs/ui/src/components/button/button.component';
import {NumberRangeKeyModule} from '@libs/ui/src/components/number-range-key/number-range-key.module';
import {MissingEntriesInfoModule} from '@core/components/missing-entries-info/missing-entries-info.module';
import {CustomerDropdownModule} from '@customer/components/dropdowns/customer-dropdown/customer-dropdown.module';


@NgModule({
    declarations: [
        BoardSelectComponent,
        WorkFlowBoardComponent,
        TicketCardComponent,
        BoardStateComponent,
        BoardSelectRowComponent,
        BoardCreateDialogComponent,
        MotherBoardComponent,
        ListBoardComponent,
        TicketBadgeFilterDialogComponent,
        BoardHeaderComponent,
        BoardActionMenuComponent,
        BoardSidenavComponent,
        SidenavColorSelectorComponent,
        BoardSidenavColorOptionComponent,
        TimeBoardComponent,
        EditTimeBoardTicketDialogComponent,
        TimeBoardWeekComponent,
        TimeBoardMonthComponent,
        TimeBoardDayComponent,
        AssignedTicketBoardComponent,
        ActionlessBoardStateComponent,
        AssignedTicketBoardHeaderComponent,
        AssignedTicketSideNavComponent,
        BoardSelectFilterComponent,
        BoardSelectFilterPipe,
        AssignedTicketSideNavComponent,
        TimeBoardUserSchedulerComponent
    ],
    exports: [TicketCardComponent],
    imports: [
        CommonModule,
        BoardsRoutingModule,
        FlexModule,
        MatIconModule,
        MatButtonModule,
        CoreModule,
        MatCardModule,
        ScrollingModule,
        DragDropModule,
        MatTooltipModule,
        FormsModule,
        NgSelectModule,
        ProjectModule,
        MatDialogModule,
        MatMenuModule,
        FuseDirectivesModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatChipsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        MatSidenavModule,
        MatDividerModule,
        MatRippleModule,
        FuseSharedModule,
        MatListModule,
        MatGridListModule,
        CalendarModule.forRoot({
                provide: DateAdapter,
                useFactory: adapterFactory
            }
        ),
        ColorPickerModule,
        MatButtonToggleModule,
        SharedModule,
        DatePickerModule,
        TimeSelectModule,
        HeaderModule,
        HelpIconModule,
        ImageCropperModule,
        SearchInputModule,
        SpinnerModule,
        TicketTypeModule,
        TicketStateDropdownModule,
        AvatarModule,
        TimeBoardWeekSchedulerModule,
        TimeBoardWeekSchedulerModule,
        DialogHostModule,
        CurrentTicketModule,
        BusinessUnitDropdownModule,
        ProjectDropdownModule,
        BookmarkButtonModule,
        DeleteIconModule,
        FavoriteButtonComponent,
        ButtonComponent,
        NumberRangeKeyModule,
        MissingEntriesInfoModule,
        CustomerDropdownModule
    ]
})
export class BoardsModule {
}
