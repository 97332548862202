import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {TimeBoardSchedulerService} from '@app/boards/components/time-board/time-board-user-scheduler/time-board-scheduler.service';
import {BoardTransferModel} from '@boards/models';
import {Observable} from 'rxjs';
import {TimeBoardAssignee} from '@boards/components/time-board/time-board-user-scheduler/time-board-assignee';
import {TimeBoardResourceTicket} from '@boards/components/time-board/time-board-user-scheduler/time-board-resource-ticket';
import {TimeBoardScheduledEvent} from '@boards/components/time-board/time-board-user-scheduler/time-board-scheduled-event';
import {datesAreOnSameDay} from '@core/utils/date-utils';

@Component({
    selector: 'omt-time-board-user-scheduler',
    templateUrl: './time-board-user-scheduler.component.html',
    styleUrls: ['./time-board-user-scheduler.component.scss']
})
export class TimeBoardUserSchedulerComponent {
    @Input() set board(board: BoardTransferModel) {
        if (!board) {
            return;
        }

        this._board = board;
        this.assignBoardResources(board).then();
    }

    get board(): BoardTransferModel {
        return this._board;
    }

    private _board: BoardTransferModel;

    viewDate = new Date();
    assignees$: Observable<TimeBoardAssignee[]>;
    resourceTickets$: Observable<TimeBoardResourceTicket[]>;
    entries$: Observable<TimeBoardScheduledEvent[]>;
    isLoading = true;

    constructor(private readonly timeBoardSchedulerService: TimeBoardSchedulerService,
                private readonly cdRef: ChangeDetectorRef) {
    }

    addEntry(entry: TimeBoardScheduledEvent): void {
        entry.ticketId = (entry.resource as TimeBoardResourceTicket).ticketId;
        this.timeBoardSchedulerService.assignResource(entry).subscribe();
    }

    updateEntry(entry: TimeBoardScheduledEvent): void {
        this.timeBoardSchedulerService.assignResource(entry).subscribe();
    }

    resetEntry(entry: TimeBoardScheduledEvent): void {
        this.timeBoardSchedulerService.resetEntry(entry).subscribe();
    }

    private async assignBoardResources(model: BoardTransferModel): Promise<void> {
        this.isLoading = true;
        const week = this.viewDate.getWeekStartAndEnd();
        await this.timeBoardSchedulerService.setBoard(model, {start: week.start, end: week.end});

        this.assignees$ = this.timeBoardSchedulerService.assignees$;
        this.resourceTickets$ = this.timeBoardSchedulerService.resources$;
        this.entries$ = this.timeBoardSchedulerService.scheduledEvents$;

        this.isLoading = false;
        this.cdRef.markForCheck();
    }

    handleViewDateChange(newDate: Date): void {
        if (datesAreOnSameDay(this.viewDate, newDate)) {
            return;
        }

        this.viewDate = newDate;
        this.assignBoardResources(this.board).then();
    }
}
