import {Component} from '@angular/core';
import {TimeBoardService} from '@app/boards/components/time-board/time-board.service';

@Component({
    selector: 'omt-time-board-week',
    templateUrl: './time-board-week.component.html',
    styleUrls: ['./time-board-week.component.scss']
})
export class TimeBoardWeekComponent {
    constructor(public tbs: TimeBoardService) {
    }

}
