<div class="sidenav-headline" fxLayout="column" fxLayoutAlign="center center" style="width: 330px">
  <div>Filter</div>
</div>

<div style="padding: 10px" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  @if (customers && mode !== 'customer') {
    <ng-select
      [(ngModel)]="customerFilter"
      style="width: 100%"
      [items]="customers | sortBy: 'asc':'name'"
      bindLabel="name"
      bindValue="id"
      [placeholder]="'TICKET.CUSTOMER' | translate"
      (change)="updateCustomer()"
    ></ng-select>
  }

  @if (users && mode !== 'user') {
    <ng-select
      [(ngModel)]="userFilter"
      style="width: 100%"
      [items]="users | sortBy: 'asc':'displayName'"
      bindLabel="displayName"
      bindValue="id"
      [placeholder]="'TICKET.USER' | translate"
      (change)="updateUser()"
    ></ng-select>
  }

  @if (projects && mode !== 'project') {
    <ng-select
      [(ngModel)]="projectFilter"
      style="width: 100%"
      [items]="projects | sortBy: 'asc':'name'"
      bindLabel="name"
      bindValue="id"
      [placeholder]="'TICKET.PROJECT' | translate"
      (change)="updateProject()"
    ></ng-select>
  }
</div>
