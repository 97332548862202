import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BoardBaseStateActionModel, BoardStateAction} from '@boards/models';
import {ApiService} from '@core/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class BoardStateActionService {

    constructor(private readonly http: ApiService) {
    }

    getDefaultValues(): Observable<BoardBaseStateActionModel[]> {
        return this.http.get<BoardBaseStateActionModel[]>('api/BoardStateAction/GetDefaultValues');
    }

    getBoardStateActions(boardStateId: number): Observable<BoardStateAction[]> {
        const params = new HttpParams().set('boardStateId', boardStateId.toString());
        return this.http.get<BoardStateAction[]>('api/BoardStateAction/GetBoardStateActions', params);
    }

    addBoardStateAction(boardStateAction: BoardStateAction): Observable<BoardStateAction> {
        return this.http.post<BoardStateAction>('api/BoardStateAction/AddBoardStateAction', boardStateAction);
    }

    deleteBoardStateAction(boardStateActionId: number): Observable<BoardStateAction> {
        const params = new HttpParams().set('boardStateActionId', boardStateActionId.toString());
        return this.http.delete<BoardStateAction>('api/BoardStateAction/DeleteBoardStateAction', params);
    }

}
