import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {ConfirmDialogComponent} from '@shared/components/delete-dialog/confirm-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {BoardStateActionService} from '@boards/board-state-action.service';
import {BoardHttpService} from '@boards/board-http.service';
import {BoardBaseStateActionModel, BoardStateAction, BoardStateBaseActionParam} from '@boards/models';
import {HeaderService} from '@core/components/header/header.service';
import {DetailPageHeaderDefaultConfig} from '@core/components/header/header.config';
import {ConfirmDialogComponentData} from '@shared/components/delete-dialog/confirm-dialog-component-data';

@Component({
    selector: 'omt-board-action-menu',
    templateUrl: './board-action-menu.component.html',
    styleUrls: ['./board-action-menu.component.scss']
})
export class BoardActionMenuComponent implements OnInit {
    stateId: number;
    $boardBaseStateActions: Observable<BoardBaseStateActionModel[]>;
    $boardName: Observable<{ boardName: string }>;
    boardStateActions: BoardStateAction[];
    selectedBoardBaseStateAction: BoardBaseStateActionModel;
    selectedBoardBaseStateActionParam: BoardStateBaseActionParam;
    additionalParam: string;
    dataSource = new MatTableDataSource();
    displayedColumns = ['action', 'param', 'customparam', 'delete'];
    private paginator: MatPaginator;

    constructor(private readonly activatedRoute: ActivatedRoute,
                private readonly boardHttpService: BoardHttpService,
                private readonly boardStateActionService: BoardStateActionService,
                private readonly dialog: MatDialog,
                private readonly headerService: HeaderService) {
    }

    @ViewChild(MatPaginator, {static: false}) set page(content: MatPaginator) {
        this.paginator = content;
        if (this.paginator) {
            this.dataSource.paginator = this.paginator;
        }

    }

    ngOnInit(): void {
        this.headerService.config = {...DetailPageHeaderDefaultConfig, isVisible: true};
        this.stateId = +this.activatedRoute.snapshot.paramMap.get('id') as number;

        this.boardStateActionService.getBoardStateActions(this.stateId).subscribe((value) => {
            this.boardStateActions = value;
            this.tableConfiguration(this.boardStateActions);
        });

        this.boardHttpService.getBoardNameByState(this.stateId).subscribe((name) => this.headerService.setTitle(`${name.boardName} - Boardstatus-Aktionen`));
        this.$boardBaseStateActions = this.boardStateActionService.getDefaultValues();
    }


    addAction(): void {
        const action = new BoardStateAction();
        action.boardStateId = this.stateId;
        action.boardStateBaseActionId = this.selectedBoardBaseStateAction.baseAction.id;
        action.boardStateBaseActionParamId = this.selectedBoardBaseStateActionParam.id;
        action.boardStateBaseActionAdditionalParam = this.additionalParam;
        this.boardStateActionService.addBoardStateAction(action).subscribe((x) => {
            this.boardStateActions.push(x);
            this.tableConfiguration(this.boardStateActions);
        });
    }

    deleteAction(id: number): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '420px',
            position: {top: '15%'},
            data: new ConfirmDialogComponentData('Aktion löschen', 'Wollen Sie diese Aktion wirklich löschen?')
        });

        dialogRef.componentInstance.deleteClicked.subscribe((confirmed) => {
            if (confirmed) {
                this.boardStateActionService.deleteBoardStateAction(id).subscribe(() => {
                    this.boardStateActions = this.boardStateActions.filter((y) => y.id !== id);
                    this.tableConfiguration(this.boardStateActions);
                });
            }
        });
    }

    tableConfiguration(items: BoardStateAction[]): void {
        this.dataSource = new MatTableDataSource<BoardStateAction>(items);

        this.dataSource.paginator = this.paginator;
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }

    }

}
