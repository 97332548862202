import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DialogService} from '@core/services/global/dialog.service';
import {ConfirmDialogComponentData} from '@shared/components/delete-dialog/confirm-dialog-component-data';
import {Router} from '@angular/router';
import {SubSink} from 'subsink';
import {BoardHttpService} from '@boards/board-http.service';
import {BoardStateModel} from '@boards/models';
import {switchMap} from 'rxjs/operators';

@Component({
    selector: 'omt-board-state',
    templateUrl: './board-state.component.html',
    styleUrls: ['./board-state.component.scss']
})
export class BoardStateComponent {
    @HostBinding('class.collapsed') get collapsed(): boolean {
        return this.isCollapsed;
    }

    @Input() state = new BoardStateModel();
    @Input() boardId;
    @Input() fullHeight: number;
    @Input() backColor: string;
    @Input() fontColor: string;
    @Output() stateDeleted = new EventEmitter();

    isCollapsed = false;

    private subs = new SubSink();

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    constructor(
        public dialog: MatDialog,
        private readonly boardService: BoardHttpService,
        private readonly dialogService: DialogService,
        private readonly router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    updateBoardState(): void {
        this.subs.sink = this.boardService.updateBoardState(this.state).subscribe((data) => {
            this.state = data;
            this.state.editable = false;
        });
    }

    delete(): void {
        this.subs.sink = this.boardService.deleteBoardState(this.state.id).subscribe((x) => {
            this.stateDeleted.emit(x.id);
        });
    }

    toggleCollapsed(event: Event): void {
        event.stopPropagation();
        this.isCollapsed = !this.isCollapsed;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    openDialog(): void {
        const dialogData = new ConfirmDialogComponentData('Liste löschen', 'Soll diese Liste wirklich gelöscht werden? ' +
            'Alle darin befindlichen Tickets können Sie weiterhin über die Ticketliste abrufen.', 'Abbrechen', 'Löschen');
        const dialogRef = this.dialogService.openConfirmDialog(dialogData);

        this.subs.sink = dialogRef.componentInstance.deleteClicked.subscribe((confirmed) => {
            if (confirmed) {
                this.delete();
            }
        });
    }


    openNewTicketDialog(): void {
        this.dialogService.openTicketDialog({
            boardStateId: this.state.id,
            boardId: this.boardId
        });
    }

    openTicketDialog(): void {
        const dialogRef = this.dialogService.ticketSelectionDialog(this.boardId, this.state.id, this.state.boardTickets.map((x) => x.ticket.id));

        this.subs.sink = dialogRef.componentInstance.selectionChanged.pipe(switchMap((x) => {
            const ticketIds = x.selected.map((z) => z.id);
            return this.boardService.createTicketLinks(ticketIds, this.boardId, this.state.id);
        })).subscribe();
    }

    openBoardActionMenu(): void {
        this.router.navigate([`../boards/board-action-menu/${this.state.id}`]).then();
    }
}
