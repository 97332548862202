import {Component, Input} from '@angular/core';
import {TicketStateTask} from '../assigned-ticket-board.component';

@Component({
    selector: 'omt-actionless-board-state',
    templateUrl: './actionless-board-state.component.html',
    styleUrls: ['../../workflow-board/board-state/board-state.component.scss']
})
export class ActionlessBoardStateComponent {
    @Input() fullHeight = 1000;
    @Input() state: TicketStateTask;
}
