import {CalendarEntry} from '@core/components/resource-scheduler-calendar/calendar-entry';
import {CalendarResource} from '@core/components/resource-scheduler-calendar/calendar-resource';
import {v4} from 'uuid';

export class TimeBoardScheduledEvent implements CalendarEntry {
    constructor(ticketId?: number, businessUnitId?: number, name?: string, dateFrom?: Date, dateTo?: Date, editable?: boolean, resource?: CalendarResource) {
        this.unitId = businessUnitId;
        this.ticketId = ticketId;
        this.name = name;
        this.from = dateFrom;
        this.to = dateTo;
        this.resource = resource;
        this.editable = editable;
    }

    unitId: number;
    ticketId: number | null;
    from: Date;
    name: string;
    resource: CalendarResource;
    to: Date;
    uniqueId = v4();
    color: string;
    editable: boolean;
}
