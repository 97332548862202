<div class="views" (click)="$event.stopPropagation()">
  @switch (view) {
    @case ('main') {
      <div class="view" [@slideInLeft]>
        <!-- SIDENAV CONTENT -->
        <div class="content" fusePerfectScrollbar>
          <div class="nav material2">
            <!--         Aktionen                     -->
            <div class="sidenav-headline" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{'background-color': boardTransferModel.mainColor}">
              <div [ngStyle]="{color: boardTransferModel.mainFontColor}">{{ 'BOARD.PICTURE' | translate }}</div>
            </div>
            @if (!boardTransferModel.boardImageUrl) {
              <omt-image-cropper (imageSelected)="uploadBoardImage($event)">
                <div fxLayout="row" fxLayoutAlign="center" style="padding: 16px">
                  <div fxLayout="row" fxLayoutAlign="center" style="border: 2px dashed #55575b">
                    <mat-icon class="board-fa-big cursor-pointer" [ngStyle]="{color: boardTransferModel.mainColor}">assessment</mat-icon>
                  </div>
                </div>
              </omt-image-cropper>
            }
            @if (boardTransferModel.boardImageUrl) {
              <omt-image-cropper (imageSelected)="uploadBoardImage($event)">
                <div fxLayout="row" fxLayoutAlign="center" style="padding: 16px">
                  <div fxLayout="row" fxLayoutAlign="center" style="width: 85%">
                    <img [src]="boardTransferModel.boardImageUrl"
                         class="board-fa-big cursor-pointer"
                         alt="Board-Image"
                         (error)="boardTransferModel.boardImageUrl = null"/>
                  </div>
                </div>
              </omt-image-cropper>
            }
            <div clasS="sidenav-headline" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{'background-color': boardTransferModel.mainColor}">
              <div [ngStyle]="{color: boardTransferModel.mainFontColor}">{{ 'GLOBAL.FAVORITE' | translate }}</div>
            </div>
            <div class="nav-item" fxLayout="row" fxLayoutAlign="center center">
              <omt-favorite-button class="m-1" [entityId]="boardTransferModel.id" type="board" size="big"></omt-favorite-button>
            </div>
            <!--         Aktionen                     -->
            <div class="sidenav-headline" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{'background-color': boardTransferModel.mainColor}">
              <div [ngStyle]="{color: boardTransferModel.mainFontColor}">{{ 'BOARD.BOARD_ACTIONS' | translate }}</div>
            </div>
            <div class="nav-item" (click)="openTicketDialog()">
              <div class="nav-option" matRipple>
                <i class="fas fa-pen"></i>
                <span class="title">{{ 'BOARD.ADD_EXISTING_TICKETS' | translate }}</span>
              </div>
            </div>
            <div class="nav-item" (click)="openRenameDialog()">
              <div class="nav-option" matRipple>
                <i class="fas fa-pen"></i>
                <span class="title">{{ 'BOARD.RENAME' | translate }}</span>
              </div>
            </div>
            <div class="nav-item" (click)="openCopyDialog()">
              <div class="nav-option" matRipple>
                <i class="fas fa-copy"></i>
                <span class="title">{{ 'BOARD.COPY' | translate }}</span>
              </div>
            </div>
            <div class="nav-item" [matTooltip]="'BOARD.DELETE_BOARD' | translate" (click)="openDeleteDialog()">
              <div class="nav-option" matRipple>
                <i class="fas fa-trash cursor-pointer"></i>
                <span class="title">{{ 'GLOBAL.DELETE' | translate }}</span>
              </div>
            </div>
            <!--         EINSTELLUNGEN                     -->
            <div class="sidenav-headline" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{'background-color': boardTransferModel.mainColor}">
              <div [ngStyle]="{color: boardTransferModel.mainFontColor}">{{ 'BOARD.SETTINGS' | translate }}</div>
            </div>
            <div class="nav-item">
              <div class="nav-option" matRipple>
                <mat-checkbox
                  style="color: black"
                  color="primary"
                  [(ngModel)]="hideCompleted"
                  (change)="changeCompletedFilter()"
                  [disabled]="isListBoard()"
                  [matTooltip]="'BOARD.HIDE_ALL_TICKETS_DAYS' | translate"
                >{{ 'BOARD.HIDE_LOCKED_TICKETS' | translate }}
                </mat-checkbox>
              </div>
            </div>
            <!--         FARBEN                     -->
            <div class="sidenav-headline" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{'background-color': boardTransferModel.mainColor}">
              <div [ngStyle]="{color: boardTransferModel.mainFontColor}">{{ 'BOARD.COLOURS' | translate }}</div>
            </div>
            <!--                            mainFontColor                                         -->
            <div class="nav-item">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <button
                  mat-button
                  style="text-align: center"
                  [cpPosition]="'bottom'"
                  [cpPositionOffset]="'50%'"
                  [cpPositionRelativeToArrow]="true"
                  [(colorPicker)]="boardTransferModel.mainFontColor"
                  (colorPickerClose)="setBoardColor(boardTransferModel.mainFontColor, 'mainFontColor')"
                >
                  <i class="fas fa-palette"></i>
                  {{ 'BOARD.HEADLINE' | translate }}
                </button>
                <div style="width: 15px; height: 15px; margin-right: 20px" [style.background-color]="boardTransferModel.mainFontColor"></div>
              </div>
            </div>
            <!--                            mainFontColor                                         -->
            <!--                            mainColor                                         -->
            <div class="nav-item">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <button
                  mat-button
                  style="text-align: center"
                  [cpPosition]="'bottom'"
                  [cpPositionOffset]="'50%'"
                  [cpPositionRelativeToArrow]="true"
                  [(colorPicker)]="boardTransferModel.mainColor"
                  (colorPickerClose)="setBoardColor(boardTransferModel.mainColor, 'mainColor')"
                >
                  <i class="fas fa-palette"></i>
                  {{ 'BOARD.HEADGROUND' | translate }}
                </button>
                <div style="width: 15px; height: 15px; margin-right: 20px" [style.background-color]="boardTransferModel.mainColor"></div>
              </div>
            </div>
            <!--                            mainColor                                         -->
            @if (boardTransferModel.boardTypeId === 2) {
              <!--                            secondFontColor                                         -->
              <div class="nav-item">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <button
                    mat-button
                    style="text-align: center"
                    [cpPosition]="'bottom'"
                    [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true"
                    [(colorPicker)]="boardTransferModel.secondFontColor"
                    (colorPickerClose)="setBoardColor(boardTransferModel.secondFontColor, 'secondFontColor')"
                  >
                    <i class="fas fa-palette"></i>
                    {{ 'BOARD.STATUS_TEXT' | translate }}
                  </button>
                  <div style="width: 15px; height: 15px; margin-right: 20px" [style.background-color]="boardTransferModel.secondFontColor"></div>
                </div>
              </div>
              <!--                            secondFontColor                                         -->
                <!--                            secondColor                                         -->
              <div class="nav-item">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <button
                    mat-button
                    style="text-align: center"
                    [cpPosition]="'bottom'"
                    [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true"
                    [(colorPicker)]="boardTransferModel.secondColor"
                    (colorPickerClose)="setBoardColor(boardTransferModel.secondColor, 'secondColor')"
                  >
                    <i class="fas fa-palette"></i>
                    {{ 'BOARD.STATUS_BACKGROUND' | translate }}
                  </button>
                  <div style="width: 15px; height: 15px; margin-right: 20px" [style.background-color]="boardTransferModel.secondColor"></div>
                </div>
              </div>
              <!--                            secondColor                                         -->
                <!--                            secondAccentColor                                         -->
              <div class="nav-item">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <button
                    mat-button
                    style="text-align: center"
                    [cpPosition]="'bottom'"
                    [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true"
                    [(colorPicker)]="boardTransferModel.secondAccentColor"
                    (colorPickerClose)="setBoardColor(boardTransferModel.secondAccentColor, 'secondAccentColor')"
                  >
                    <i class="fas fa-palette"></i>
                    {{ 'BOARD.STATUS_ACCENT' | translate }}
                  </button>
                  <div style="width: 15px; height: 15px; margin-right: 20px" [style.background-color]="boardTransferModel.secondAccentColor"></div>
                </div>
              </div>
              <!--                            secondAccentColor                                         -->
                <!--                            thirdFontColor                                         -->
              <div class="nav-item">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <button
                    mat-button
                    style="text-align: center"
                    [cpPosition]="'bottom'"
                    [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true"
                    [(colorPicker)]="boardTransferModel.thirdFontColor"
                    (colorPickerClose)="setBoardColor(boardTransferModel.thirdFontColor, 'thirdFontColor')"
                  >
                    <i class="fas fa-palette"></i>
                    {{ 'BOARD.MAP_FONT' | translate }}
                  </button>
                  <div style="width: 15px; height: 15px; margin-right: 20px" [style.background-color]="boardTransferModel.thirdFontColor"></div>
                </div>
              </div>
              <!--                            thirdFontColor                                         -->
                <!--                            thirdColor                                         -->
              <div class="nav-item">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <button
                    mat-button
                    style="text-align: center"
                    [cpPosition]="'bottom'"
                    [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true"
                    [(colorPicker)]="boardTransferModel.thirdColor"
                    (colorPickerClose)="setBoardColor(boardTransferModel.thirdColor, 'thirdColor')"
                  >
                    <i class="fas fa-palette"></i>
                    {{ 'BOARD.MAP_BACK' | translate }}
                  </button>
                  <div style="width: 15px; height: 15px; margin-right: 20px" [style.background-color]="boardTransferModel.thirdColor"></div>
                </div>
              </div>
              <!--                            thirdColor                                         -->
                <!--                            thirdAccentColor                                         -->
              <div class="nav-item">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <button
                    mat-button
                    style="text-align: center"
                    [cpPosition]="'bottom'"
                    [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true"
                    [(colorPicker)]="boardTransferModel.thirdAccentColor"
                    (colorPickerClose)="setBoardColor(boardTransferModel.thirdAccentColor, 'thirdAccentColor')"
                  >
                    <i class="fas fa-palette"></i>
                    {{ 'BOARD.MAP_ACCENT' | translate }}
                  </button>
                  <div style="width: 15px; height: 15px; margin-right: 20px" [style.background-color]="boardTransferModel.thirdAccentColor"></div>
                </div>
              </div>
              <!--                            thirdAccentColor                                         -->
            }
          </div>
        </div>
        <!-- / SIDENAV CONTENT -->
      </div>
    }
  }
</div>
