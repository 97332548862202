import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BoardTransferModel} from '@boards/models';


@Component({
    selector: 'omt-board-header',
    templateUrl: './board-header.component.html',
    styleUrls: ['./board-header.component.scss']
})
export class BoardHeaderComponent {
    @Input() boardTransferModel: BoardTransferModel;
    @Output() settingsToggled = new EventEmitter();
    editMode = false;

    constructor() {
    }
}
