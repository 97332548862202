<mwl-calendar-week-view
  [viewDate]="tbs.viewDate"
  [events]="tbs.events"
  [locale]="'de'"
  [hourSegmentTemplate]="hourSegmentTemplate"
  [refresh]="tbs.refresh"
  (eventTimesChanged)="tbs.eventTimesChanged($event)"
  [dayStartHour]="tbs.startHour"
  [dayEndHour]="tbs.endHour"
  [eventTemplate]="weekTemplate"
>
  <ng-template #hourSegmentTemplate let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight" let-isTimeLabel="isTimeLabel">
    <div
      class="cal-hour-segment"
      [style.height.px]="segmentHeight"
      [class.cal-hour-start]="segment.isStart"
      [class.cal-after-hour-start]="!segment.isStart"
      [ngClass]="segment.cssClass"
    >
      @if (isTimeLabel) {
        <div class="cal-time">
          {{ segment.date | timeFormat }}
        </div>
      }
    </div>
  </ng-template>

  <ng-template
    #weekTemplate
    let-weekEvent="weekEvent"
    let-tooltipPlacement="tooltipPlacement"
    let-eventClicked="eventClicked"
    let-tooltipTemplate="tooltipTemplate"
    let-tooltipAppendToBody="tooltipAppendToBody"
    let-tooltipDisabled="tooltipDisabled"
    let-tooltipDelay="tooltipDelay"
    let-column="column"
    let-daysInWeek="daysInWeek"
  >
    <div
      class="cal-event"
      [ngStyle]="{backgroundColor: weekEvent.event.color?.secondary, borderColor: weekEvent.event.color?.primary}"
      [mwlCalendarTooltip]="!tooltipDisabled ? (weekEvent.event.title | calendarEventTitle: (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip'):weekEvent.event) : ''"
      [tooltipPlacement]="tooltipPlacement"
      [tooltipEvent]="weekEvent.event"
      [tooltipTemplate]="tooltipTemplate"
      [tooltipAppendToBody]="tooltipAppendToBody"
      [tooltipDelay]="tooltipDelay"
      (mwlClick)="eventClicked.emit({sourceEvent: $event})"
      (mwlKeydownEnter)="eventClicked.emit({sourceEvent: $event})"
      tabindex="0"
      role="application"
      [attr.aria-label]="{event: weekEvent.event, locale: 'de'} | calendarA11y: 'eventDescription'"
      [style.color]="weekEvent.event.meta.fontColor"
    >
      <button class="calender-month-setting" mat-icon-button [matMenuTriggerFor]="ticketMenu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #ticketMenu="matMenu">
        <button mat-menu-item (click)="tbs.openEditDialog(weekEvent.event)">Bearbeiten</button>
        <button mat-menu-item (click)="tbs.copyBoardTicket(weekEvent.event)">Kopieren</button>
        <button mat-menu-item (click)="tbs.openDeleteDialog(weekEvent.event)">Löschen</button>
      </mat-menu>

      <img
        [src]="weekEvent.event.meta.ticket.assigneeAvatar"
        class="cursor-pointer border-radius-50 calender-month-avatar"
        [matTooltip]="weekEvent.event.meta.ticket.assigneeName"
      />

      <span (click)="tbs.openTicket(weekEvent.event.meta.ticket.key)" style="cursor: pointer"> {{ weekEvent.event.meta.ticket.title }}</span>

      <br/>
      <div style="text-align: center; margin-top: -13px">{{ weekEvent.event.meta.dateFrom | timeFormat }} - {{ weekEvent.event.meta.dateTo | timeFormat }}</div>
    </div>
  </ng-template>
</mwl-calendar-week-view>
