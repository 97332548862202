<div class="scheduler-container">
  <div class="scheduler-action-bar">
    <div fxLayout="row" fxLayoutGap="1rem">
      <button mat-icon-button (click)="prevDate()">
        <i class="fas fa-chevron-left text-black"></i>
      </button>
      <omt-date-picker [(ngModel)]="viewDate" class="w-150"></omt-date-picker>
      <button mat-icon-button (click)="nextDate()">
        <i class="fas fa-chevron-right text-black"></i>
      </button>
    </div>
  </div>

  <div cdkDropListGroup fxLayout="row" fxLayoutGap="15px" class="h-100-p">
    <div fxFlex="15" class="scheduler-resources">
      <h3 class="mb-0">Ressourcen</h3>
      <omt-search-input displayMode="full" (searchChanged)="resourceSearchFilter($event)" class="d-block mt-1 mb-1"></omt-search-input>
      <cdk-virtual-scroll-viewport id="resources" cdkDropList (cdkDropListDropped)="handleResourceDrop($event)" class="scheduler-viewport" itemSize="55">
        <mat-card
          *cdkVirtualFor="let resource of calendar.resources; trackBy: trackResourceByFn"
          [cdkDragConstrainPosition]="constrainFn"
          [cdkDragData]="resource"
          cdkDrag
          cdkDragBoundary=".scheduler-container"
          fxLayoutAlign="center center"
          class="scheduler-resource cursor-grab"
          style="margin-bottom: 5px"><strong>{{ resource.name }}</strong>
        </mat-card>

        @if (calendar.resources.length === 0) {
          <span class="no-resources-message">Keine Ressourcen vorhanden.</span>
        }
      </cdk-virtual-scroll-viewport>
    </div>

    <div class="time-board-week-scheduler" cdkScrollable>
      <table class="week-day-container-table">
        <thead>
        <tr>
          <th class="unit-cell"><h3 class="mb-0">Mitarbeiter</h3></th>
          @for (date of calendar.dates; track date) {
            <th class="week-day-header">
              <h3 class="mb-0">{{ date | date: 'EEEE, dd.MM.' }}</h3>
            </th>
          }
        </tr>
        </thead>

        <tbody class="week-day-table-body">
          @for (zone of calendar.zones; track zone) {
            <tr [id]="zone.unit.id" class="unit">
              <td class="unit-name">
                <ng-container *ngTemplateOutlet="unitTemplate ?? defaultUnitTemplate; context: {$implicit: zone.unit}"></ng-container>
              </td>
              @for (day of zone.days; track day) {
                <td>
                  <div class="w-100-p h-100-p position-relative">
                    <div
                      cdkDropList="dropList"
                      cdkDropListSortingDisabled="true"
                      cdkDropListOrientation="horizontal"
                      [ngClass]="{weekend: isWeekend(day.date)}"
                      (cdkDropListDropped)="handleDrop($event, day, zone)"
                      class="week-day-scheduler-zone"
                    >
                      @for (entry of day.events; track trackByFn($index, entry)) {
                        <div
                          #entryElem
                          class="calendar-entry"
                          [id]="entry.uniqueId"
                          [class.fixed]="!entry.editable"
                          [style.width.%]="100 / displayHours.length | calendarEntryWidth: entry"
                          [style.left.%]="100 / displayHours.length | calendarEntryPosition: displayHours[0]:entry"
                          mwlResizable
                          [enableGhostResize]="true"
                          (resizeEnd)="handleResize($event, entryElem, entry)"
                        >
                          <div class="entry-wrapper">
                            <div mwlResizeHandle [resizeEdges]="{left: true}" class="resize-handle left"></div>
                            <div
                              class="entry"
                              [cdkDragConstrainPosition]="constrainFn"
                              [cdkDragData]="entry"
                              [cdkDragDisabled]="!entry.editable"
                              cdkDrag
                              cdkDragBoundary=".scheduler-container"
                              (click)="entryClicked.emit(entry)"
                            >
                              <ng-container *ngTemplateOutlet="calendarEntryTemplate ?? defaultEntryTemplate; context: {$implicit: entry}"></ng-container>
                              <div *cdkDragPlaceholder></div>
                            </div>
                            <div mwlResizeHandle [resizeEdges]="{right: true}" class="resize-handle right"></div>
                          </div>
                        </div>
                      }
                    </div>
                    <div [classList]="hourContainerClass + ' hour-grid'">
                      @for (hour of displayHours; track hour) {
                        <div [attr.hour]="hour" class="hour-grid-item"></div>
                      }
                    </div>
                  </div>
                </td>
              }
            </tr>
          }
        </tbody>

        <tfoot class="calendar-footer">
        <tr>
          <td></td>
          @for (date of calendar.dates; track date) {
            <td>
              <div class="hour-segments">
                @for (hour of displayHours; track hour) {
                  <div [ngClass]="{active:
                   current.getHours() === hour
                   && date.getFullYear() === current.getFullYear()
                   && date.getMonth() === current.getMonth()
                   && date.getDay() === current.getDay()
               }" class="hour-segment">
                    <div class="hour">{{ hour }}</div>
                  </div>
                }
              </div>
            </td>
          }
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<ng-template #defaultEntryTemplate let-entry>
  <mat-card fxLayoutAlign="center center"><strong>{{ entry.name }}</strong></mat-card>
</ng-template>

<ng-template #defaultUnitTemplate let-unit>
  <span>{{ unit.name | empty }}</span>
</ng-template>

<ng-template #defaultPlaceholderTemplate let-entry>
  <mat-card fxLayoutAlign="center center"><strong>{{ entry.name }}</strong></mat-card>
</ng-template>
