import {Pipe, PipeTransform} from '@angular/core';
import {CalendarEntry} from '@core/components/resource-scheduler-calendar/calendar-entry';

@Pipe({
    name: 'calendarEntryWidth',
    pure: true
})
export class CalendarEntryWidthPipe implements PipeTransform {
    transform(hourWidth: number, entry: CalendarEntry): number {
        const hours = Math.abs(entry.to.getTime() - entry.from.getTime()) / 1000 / 60 / 60;
        const res = hourWidth * hours + hourWidth;
        return res > 100 ? 100 : res;
    }
}
