<div id="header-small" class="board-header" fxLayout="row" fxLayoutAlign="space-between center" [ngStyle]="{'background-color': boardTransferModel.mainColor}">
  <button mat-icon-button aria-label="Settings" [matTooltip]="'BOARD.BACK_TO_BOARDS' | translate" routerLink="..">
    <mat-icon [ngStyle]="{color: boardTransferModel.mainFontColor}">arrow_back</mat-icon>
  </button>

  <ng-content select="[actions-left]"></ng-content>

  <span id="board-title" [ngStyle]="{color: boardTransferModel.mainFontColor}">{{ boardTransferModel.name }}</span>

  <ng-content select="[actions-right]"></ng-content>

  <button mat-icon-button (click)="settingsToggled.emit()" aria-label="Settings" matTooltip="Settings">
    <mat-icon [ngStyle]="{color: boardTransferModel.mainFontColor}">settings</mat-icon>
  </button>
</div>
