import {CalendarUnit} from '@core/components/resource-scheduler-calendar/calendar-unit';
import {BusinessUnitModel} from '@core/models/businessunit/business-unit.model';

export class TimeBoardAssignee implements CalendarUnit {
    constructor(assignee?: BusinessUnitModel) {
        this.name = assignee.displayName;
        this.id = assignee.id;
        this.userId = assignee.userId;
        this.avatarUrl = assignee.avatarUrl;
    }

    id: number;
    name: string;
    userId: number;
    avatarUrl: string;
}
