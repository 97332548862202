<div class="page-layout simple">
  <div class="content-spacer pt-0">
    <div class="w-100-p p-2 bg-white table-responsive box-shadow" fxLayout="row">
      <ng-select
        [items]="$boardBaseStateActions | async | sortBy: 'asc':'name'"
        [(ngModel)]="selectedBoardBaseStateAction"
        [placeholder]="'BOARD.SELECT_ACTION' | translate"
        bindLabel="baseAction.name"
        class="mr-1"
        appendTo="body"
        style="min-width: 230px"
      ></ng-select>

      @if (selectedBoardBaseStateAction && selectedBoardBaseStateAction.params) {
        <ng-select
          [items]="selectedBoardBaseStateAction.params | sortBy: 'asc':'name'"
          [(ngModel)]="selectedBoardBaseStateActionParam"
          [placeholder]="'BOARD.SELECT_PARAMETER' | translate"
          bindLabel="name"
          appendTo="body"
          class="mr-1"
          style="min-width: 230px"
        ></ng-select>
      }

      @if (selectedBoardBaseStateActionParam && selectedBoardBaseStateActionParam.additionalParam) {
        <input type="text" class="redesign" [(ngModel)]="additionalParam"/>
      }

      <button mat-raised-button color="primary" [disabled]="!selectedBoardBaseStateAction || !selectedBoardBaseStateActionParam"
              (click)="addAction()">{{ 'GLOBAL.ADD' | translate }}
      </button>
    </div>

    <div class="w-100-p p-2 mt-1 bg-white table-responsive box-shadow">
      <table mat-table matSort [dataSource]="dataSource">
        <!-- Due Date Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'BOARD.ACTION' | translate }}</th>
          <td mat-cell *matCellDef="let action">{{ action.boardStateBaseAction.name }}</td>
        </ng-container>

        <ng-container matColumnDef="param">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Parameter</th>
          <td mat-cell *matCellDef="let action">{{ action.boardStateBaseActionParam.name }}</td>
        </ng-container>

        <ng-container matColumnDef="customparam">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Extra Parameter</th>
          <td mat-cell *matCellDef="let action">{{ action.boardStateBaseActionAdditionalParam }}</td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let action">
            <omt-delete-icon (delete)="deleteAction(action.id)"/>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-pointer"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[15, 25, 50, 100, 250]" [pageSize]="15"></mat-paginator>
    </div>
  </div>

  <div class="content-spacer pt-0"></div>
</div>
