import {CalendarEntry} from '@core/components/resource-scheduler-calendar/calendar-entry';

export class CalendarDay {
    constructor(date: Date, events?: CalendarEntry[]) {
        this.date = date;
        this.events = events;
    }

    private _displayHours: number[];

    set displayHours(hours: number[]) {
        this._displayHours = hours;
    }

    get nonAllocatedHours(): number[] {
        if (!this._displayHours) {
            return [];
        }

        const allocatedHours = [];
        this.events.forEach((event) => {
            const eventStart = event.from.getHours();
            const diff = event.to.getHours() - eventStart;

            for (let i = 0; i < diff; i++) {
                allocatedHours.push(eventStart + i);
            }

        });

        return this._displayHours.filter((hour) => !allocatedHours.find((allocatedHour) => allocatedHour === hour));
    }

    date: Date;

    private _events: CalendarEntry[] = [];

    get events(): CalendarEntry[] {
        return this._events;
    }

    set events(events: CalendarEntry[]) {
        if (!events) {
            return;
        }

        this._events = [...events];
    }
}
