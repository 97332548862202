import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {TimeBoardService} from '@app/boards/components/time-board/time-board.service';
import {CalendarView} from '@app/boards/components/time-board/calender-view';
import {BoardTransferModel} from '@boards/models';

@Component({
    selector: 'omt-time-board',
    templateUrl: './time-board.component.html',
    styleUrls: ['./time-board.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TimeBoardComponent implements OnInit, OnChanges {
    @Output() reloadBoard = new EventEmitter();
    @Input() boardTransferModel: BoardTransferModel;
    day = CalendarView.Day;
    week = CalendarView.Week;
    month = CalendarView.Month;
    view: CalendarView = this.week;
    scheduler: CalendarView.Scheduler;

    constructor(public tbs: TimeBoardService) {
    }

    ngOnInit(): void {
        this.tbs.initEvents(this.boardTransferModel);
        this.tbs.reloadBoard$.subscribe(() => this.reloadBoard.emit());
    }

    // use a setter instead of onChanges hook
    ngOnChanges(changes: SimpleChanges): void {
        this.tbs.initEvents(this.boardTransferModel);
    }
}

