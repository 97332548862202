import {TicketStateEnum} from '@core/enums/ticket-state.enum';

export const getColorFromTicketStatus = (statusId: TicketStateEnum): string => {
    const defaultColor = '#ff7c4b';

    switch (statusId) {
        case TicketStateEnum.Offen:
            return '#e3342f';
        case TicketStateEnum.Neu:
            return '#ea7b7b';
        case TicketStateEnum.InBearbeitung:
            return '#039be5';
        case TicketStateEnum.ErfolgreichAbgeschlossen:
            return '#5db937';
        case TicketStateEnum.NichtErfolgreichAbgeschlossen:
            return '#e3342f';
        case TicketStateEnum.Abgebrochen:
            return '#FF552F';
        case TicketStateEnum.Archiviert:
            return '#9e9e9e';
        default:
            return defaultColor;
    }
};
