import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

import {timer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UserModel} from '@core/models/user/user.model';
import {BoardHttpService} from '@boards/board-http.service';
import {CustomerModel} from '@customer/models';
import {CustomerService} from '@customer/customer.service';
import {
    AssignedTicketBoardHeaderComponent
} from '@boards/components/assigned-ticket-board/assigned-ticket-board-header/assigned-ticket-board-header.component';
import {UserService} from '@core/services/user.service';
import {TicketHttpService} from '@ticket/ticket-http.service';
import {ProjectModel} from '@app/project-management/models';
import {TicketListItem} from '@ticket/models';
import {ProjectHttpService} from '@app/project-management/project-http.service';
import {WindowRefService} from '@core/services/global/window-ref.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'omt-assigned-ticket-board',
    templateUrl: './assigned-ticket-board.component.html',
    styleUrls: ['./assigned-ticket-board.component.scss']
})
export class AssignedTicketBoardComponent implements OnInit {
    @ViewChild('header', {static: true}) header: AssignedTicketBoardHeaderComponent;
    title = this.translate.instant('TICKET.MY_TICKETS');

    states = [...TicketStateTasks.map((state) => {
        switch (state.id) {
            case 1:
                state.name = this.translate.instant('GLOBAL.OPEN');
                break;
            case 2:
                state.name = this.translate.instant('GLOBAL.IN_PROCESS');
                break;
            case 3:
                state.name = this.translate.instant('GLOBAL.CANCELLED');
                break;
            case 4:
                state.name = this.translate.instant('GLOBAL.SUCCESSFULLY_COMPLETED');
                break;
            case 5:
                state.name = this.translate.instant('GLOBAL.UNSUCCESSFUL_COMPLETED');
                break;
        }

        return state;
    })];

    fullHeight = 0;
    userId: number;
    customerId: number;
    projectId: number;

    customers: CustomerModel[];
    users: UserModel[];
    projects: ProjectModel[];

    userFilter: number;
    customerFilter: number;
    projectFilter: number;

    mode = 'unset';

    constructor(private readonly activatedRoute: ActivatedRoute,
                private readonly boardService: BoardHttpService,
                private readonly ticketService: TicketHttpService,
                private readonly el: ElementRef<HTMLElement>,
                private readonly ref: ChangeDetectorRef,
                private readonly customerService: CustomerService,
                private readonly userService: UserService,
                private readonly projectService: ProjectHttpService,
                private readonly router: Router,
                private readonly window: WindowRefService,
                private readonly translate: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.customerId = +this.activatedRoute.snapshot.paramMap.get('customerId') as number;
        this.projectId = +this.activatedRoute.snapshot.paramMap.get('projectId') as number;
        this.userId = +this.activatedRoute.snapshot.paramMap.get('userId') as number;

        this.getFullHeight();
        this.loadData().then(async () => this.loadTickets());
    }

    async loadData(): Promise<void> {
        await this.projectService.readAvailableProjects().toPromise().then((projects) => {
            this.projects = projects;
        });

        if (this.userId) {
            await this.userService.getUsersBelow().toPromise().then((users) => {
                this.users = users;
            });
        } else {
            await this.userService.getUsersByMandator().toPromise().then((users) => {
                this.users = users;
            });
        }

        await this.customerService.getCustomers().toPromise().then((customers) => {
            this.customers = customers;
        });
    }

    async loadTickets(): Promise<void> {
        if (this.customerId) {
            this.mode = 'customer';
            this.title = this.translate.instant('TICKET.CUSTOMER_TICKETS');
            if (this.customers || this.customers.length !== 0) {
                this.title = `Tickets: ${this.customers.find((x) => x.id === this.customerId).name}`;
            }

            await this.boardService.readAssigneeCustomerBoard(this.customerId).toPromise().then((ticketListItems) => {
                this.states.forEach((state) => {
                    state.allTickets = ticketListItems.filter((x) => x.ticketState.id === state.id);
                });
            });
        } else if (this.userId) {
            this.mode = 'user';
            this.title = this.translate.instant('TICKET.USER_TICKETS');
            if (this.users && this.users.length !== 0) {
                this.title = `Tickets: ${this.users.find((x) => x.id === this.userId).displayName}`;
            }

            await this.boardService.readAssigneeUserBoard(this.userId).toPromise().then((ticketListItems) => {
                this.states.forEach((state) => {
                    state.allTickets = ticketListItems.filter((x) => x.ticketState.id === state.id);
                });
            });
        } else if (this.projectId) {
            this.mode = 'project';
            this.title = this.translate.instant('TICKET.PROJECT_TICKETS');

            if (this.projects && this.projects.length !== 0) {
                this.title = `Tickets: ${this.projects.find((x) => x.id === this.projectId).name}`;
            }

            await this.boardService.readAssigneeProjectBoard(this.projectId).toPromise().then((ticketListItems) => {
                this.states.forEach((state) => {
                    state.allTickets = ticketListItems.filter((x) => x.ticketState.id === state.id);
                });
            });
        } else {
            this.title = this.translate.instant('TICKET.MY_TICKETS');
            this.mode = 'user';
            await this.boardService.readAssigneeUserBoard().toPromise().then((ticketListItems) => {
                this.states.forEach((state) => {
                    state.allTickets = ticketListItems.filter((x) => x.ticketState.id === state.id);
                });
            });
        }
        this.filter();
    }

    filter(): void {
        this.states.forEach((state: TicketStateTask) => {
            state.tickets = [...state.allTickets];

            if (this.userFilter) {
                state.tickets = state.tickets.filter((x) => x.assigneeId === this.userFilter);
            }

            if (this.projectFilter) {
                state.tickets = state.tickets.filter((x) => x.projectId === this.projectFilter);
            }

            if (this.customerFilter) {
                state.tickets = state.tickets.filter((x) => x.customerId === this.customerFilter);
            }
        });
        this.ref.markForCheck();
    }


    customerChange(data: CustomerModel | null): void {
        this.customerId = data?.id;
        this.router.navigate(['/boards/assigned-ticket-board/customer', data?.id]);
        this.loadTickets();
    }

    userChange(id: number | null): void {
        this.userId = id;
        this.router.navigate(['/boards/assigned-ticket-board/user', id]);
        this.loadTickets();
    }

    projectChange(data: ProjectModel | null): void {
        this.projectId = data?.id;
        this.router.navigate(['/boards/assigned-ticket-board/project', data?.id]);
        this.loadTickets();
    }

    dropTicket(state: TicketStateTask, event: CdkDragDrop<TicketListItem[]>): void {
        const item = event.previousContainer.data[event.previousIndex];

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            this.ticketService.updateTicketState(item.id, state.id).subscribe(() => {
                    item.ticketState.id = state.id;
                    item.ticketState.name = state.name;
                    this.ref.markForCheck();
                },
                () => transferArrayItem(event.container.data, event.previousContainer.data, event.currentIndex, event.previousIndex));
        }
    }

    resize(event): void {
        this.fullHeight = event.target.innerHeight - this.header.getFullHeight() - 180;
    }

    private getFullHeight(): void {
        const offset = 120;
        if (this.header.getFullHeight() === 0) {
            timer(50).subscribe(() => this.getFullHeight());
        } else {
            this.fullHeight = this.window.nativeWindow.innerHeight - this.header.getFullHeight() - offset;
        }
    }
}


const TicketStateTasks: TicketStateTask[] = [
    {
        id: 1,
        name: 'Offen',
        position: 1,
        tickets: [],
        allTickets: []
    }, {
        id: 2,
        name: 'In Bearbeitung',
        position: 2,
        tickets: [],
        allTickets: []
    }, {
        id: 3,
        name: 'Abgebrochen',
        position: 5,
        tickets: [],
        allTickets: []
    }, {
        id: 4,
        name: 'Erfolgreich abgeschlossen',
        position: 3,
        tickets: [],
        allTickets: []
    }, {
        id: 5,
        name: 'Erfolglos abgeschlossen',
        position: 4,
        tickets: [],
        allTickets: []
    }
];

export class TicketStateTask {
    id: number;
    name: string;
    position: number;
    tickets: TicketListItem[] = [];
    allTickets: TicketListItem[] = [];
}
