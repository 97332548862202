import {Component, Input} from '@angular/core';
import {DialogService} from '@core/services/global/dialog.service';
import {TicketModel} from '@ticket/models';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TicketDialogComponent} from '@ticket/ticket-dialog/ticket-dialog.component';

@Component({
    selector: 'omt-ticket-switch-card',
    templateUrl: './ticket-switch-card.component.html',
    styleUrls: ['./ticket-switch-card.component.scss']
})
export class TicketSwitchCardComponent {
    @Input() ticket: TicketModel;

    constructor(private readonly dialogService: DialogService) {
    }

    openTicket = (ticket: TicketModel): MatDialogRef<TicketDialogComponent> => this.dialogService.openTicket(ticket.ticketKey, this);
}
