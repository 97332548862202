<div class="ticket-card-list box-shadow">
  <div class="ticket-card-list-header" fxLayout="row" fxLayoutAlign="space-between">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <span>{{ state.name }}</span>
    </div>
  </div>

  <cdk-virtual-scroll-viewport [ngStyle]="{height: (fullHeight - 30) + 'px'}" itemSize="50" class="ticket-card-list-viewport">
    <ng-content></ng-content>
  </cdk-virtual-scroll-viewport>
</div>
