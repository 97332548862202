import {AfterViewInit, Component, Inject, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {SubSink} from 'subsink';
import {BoardHttpService} from '@boards/board-http.service';
import {BoardTransferModel} from '@boards/models';
import {SnackbarService} from '@core/services/snackbar.service';
import {startWith, switchMap, tap} from 'rxjs/operators';
import {BoardSidenavComponent} from '@boards/components/sidenavs/board-sidenav.component';
import {BoardTypeEnum} from '@core/enums/board-type.enum';
import {PARAM_KEY_ID, ROUTE_PARAM_TOKEN, routeParamFactory} from '@libs/ui/src';
import {EMPTY, interval, Observable} from 'rxjs';

@Component({
    selector: 'omt-mother-board',
    templateUrl: './mother-board.component.html',
    styleUrls: ['./mother-board.component.scss'],
    providers: [
        {
            provide: ROUTE_PARAM_TOKEN,
            useFactory: routeParamFactory,
            deps: [ActivatedRoute, PARAM_KEY_ID]
        }
    ]
})
export class MotherBoardComponent implements AfterViewInit, OnDestroy {
    private subs = new SubSink();

    boardId: number;
    board: BoardTransferModel;
    boardTypeEnum = BoardTypeEnum;
    isLoading = true;
    hideCompleted: boolean;
    refresh$ = interval(1000 * 60 * 3)
        .pipe(startWith(EMPTY));

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    constructor(
        @Inject(ROUTE_PARAM_TOKEN) private readonly id$: Observable<string>,
        private readonly snackbarService: SnackbarService,
        private readonly boardService: BoardHttpService,
        private readonly router: Router) {
    }

    ngAfterViewInit(): void {
        const lsEntry = localStorage.getItem(BoardSidenavComponent.hideCompletedKey);
        this.hideCompleted = JSON.parse(lsEntry) ?? true;
        this.subs.sink = this.refresh$.subscribe(() => this.loadBoard());
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    loadBoard(): void {
        this.isLoading = true;

        this.subs.sink = this.id$.pipe(
            switchMap((id) => {
                this.boardId = +id;

                if (!this.boardId) {
                    this.boardNotFound();
                }

                return this.boardService.readBoardById(this.boardId, this.hideCompleted);
            }), tap(() => this.isLoading = false)).subscribe((data) => {
            if (data != null) {
                this.board = new BoardTransferModel(data);
            } else {
                this.boardNotFound();
            }
        });
    }

    boardNotFound(): void {
        this.snackbarService.open('Board nicht vorhanden.');
        this.router.navigate(['/boards']).then();
    }
}
