<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
  <omt-search-input [(value)]="filter.filterText" (searchChanged)="onChange()"></omt-search-input>

  <button mat-button [matMenuTriggerFor]="filterMenu">Filter</button>
</div>

<mat-menu #filterMenu>
  <div mat-menu-item [matMenuTriggerFor]="filterTypeMenu">{{ 'GLOBAL.FILTER_BY_TYPE' | translate }}</div>
  <div mat-menu-item>
    <mat-checkbox [(ngModel)]="filter.groupByType" (ngModelChange)="onChange()">{{ 'GLOBAL.GROUP_BY_TYPE' | translate }}</mat-checkbox>
  </div>

  <div mat-menu-item>
    <mat-checkbox [(ngModel)]="filter.filterByDoneProjects" (ngModelChange)="onChange()">{{ 'BOARD.FILTER_COMPLETED_PROJECTS' | translate }}</mat-checkbox>
  </div>
</mat-menu>

<mat-menu #filterTypeMenu>
  <div mat-menu-item (click)="$event.stopPropagation()">
    <mat-checkbox [(ngModel)]="filter.showUserBoards" (ngModelChange)="onChange()">{{ 'BOARD.USER_BOARDS' | translate }}</mat-checkbox>
  </div>
  <div mat-menu-item (click)="$event.stopPropagation()">
    <mat-checkbox [(ngModel)]="filter.showTeamBoards" (ngModelChange)="onChange()">{{ 'BOARD.TEAM_BOARDS' | translate }}</mat-checkbox>
  </div>
  <div mat-menu-item (click)="$event.stopPropagation()">
    <mat-checkbox [(ngModel)]="filter.showProjectBoards" (ngModelChange)="onChange()">{{ 'BOARD.PROJECT_BOARDS' | translate }}</mat-checkbox>
  </div>
</mat-menu>
