import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from '@core/services/api.service';
import {BoardTicketModel, BoardTransferModel} from '@boards/models';
import {TimeBoardResourceTicket} from '@boards/components/time-board/time-board-user-scheduler/time-board-resource-ticket';
import {TimeBoardScheduledEvent} from '@boards/components/time-board/time-board-user-scheduler/time-board-scheduled-event';
import {BoardTicketAssignRequest} from '@boards/components/time-board/time-board-user-scheduler/board-ticket-assign-request';
import {TimeBoardSchedulerHttpService} from '@boards/components/time-board/time-board-user-scheduler/time-board-scheduler-http.service';
import {getColorFromTicketStatus} from '@core/utils/get-color-from-ticket-status';
import {tap} from 'rxjs/operators';
import {BoardHttpService} from '@boards/board-http.service';
import {CalendarEventModel} from '@time-tracking/calendar/models';
import {TimeBoardAssignee} from '@boards/components/time-board/time-board-user-scheduler/time-board-assignee';
import {TimeBoardEventMapper} from '@boards/components/time-board/time-board-user-scheduler/time-board-event-mapper';

@Injectable({
    providedIn: 'root'
})
export class TimeBoardSchedulerService {
    constructor(private readonly apiService: ApiService,
                private readonly httpService: TimeBoardSchedulerHttpService,
                private readonly boardHttpService: BoardHttpService) {
        this.boardHttpService.ticketLinked.subscribe((newTicket) => this.addResource(newTicket));
    }

    private assignees = new BehaviorSubject<TimeBoardAssignee[]>([]);
    assignees$ = this.assignees.asObservable();

    private resources = new BehaviorSubject<TimeBoardResourceTicket[]>([]);
    resources$ = this.resources.asObservable();

    private scheduledEvents = new BehaviorSubject<TimeBoardScheduledEvent[]>([]);
    scheduledEvents$ = this.scheduledEvents.asObservable();

    private boardTickets: BoardTicketModel[];

    async setBoard(board: BoardTransferModel, {start, end}: { start: Date; end: Date }): Promise<void> {
        const blockingEvents = await this.httpService.getBlockingEvents(board.id, {start, end}).toPromise();
        this.setResourcesAndEvents(board.boardTickets, blockingEvents.tickets, blockingEvents.events);
        await this.setAssignees(board);
    }

    setResourcesAndEvents(boardTickets: BoardTicketModel[], blockingTickets?: BoardTicketModel[], calendarEvents?: CalendarEventModel[]): void {
        this.boardTickets = boardTickets;
        const mappedTickets = TimeBoardEventMapper.mapBoardTickets(boardTickets);

        const unassigned = mappedTickets.unassigned;
        let scheduled = mappedTickets.scheduled;

        if (blockingTickets) {
            scheduled = [...scheduled, ...TimeBoardEventMapper.mapBlockingTickets(blockingTickets)];
        }

        if (calendarEvents) {
            scheduled = [...scheduled, ...TimeBoardEventMapper.mapCalendarEvents(calendarEvents)];
        }

        this.resources.next([...unassigned]);
        this.scheduledEvents.next([...scheduled]);
    }

    assignResource(resource: TimeBoardScheduledEvent): Observable<BoardTicketModel> {
        const boardTicket = this.boardTickets.find((ticket) => ticket.ticketId === resource.ticketId);
        const index = this.boardTickets.indexOf(boardTicket);
        resource.color = getColorFromTicketStatus(boardTicket.ticket.ticketState.id);

        const request: BoardTicketAssignRequest = {
            boardTicketId: boardTicket.id,
            ticketId: boardTicket.ticketId,
            dateTo: resource.to,
            dateFrom: resource.from,
            assigneeId: resource.unitId
        };

        return this.httpService.assignResource(request).pipe(tap((updatedBoardTicket) => this.boardTickets[index] = {...updatedBoardTicket}));
    }

    resetEntry(entry: TimeBoardScheduledEvent): Observable<BoardTicketModel> {
        const boardTicket = this.boardTickets.find((ticket) => ticket.ticketId === entry.ticketId);
        const index = this.boardTickets.indexOf(boardTicket);

        boardTicket.dateFrom = null;
        boardTicket.dateTo = null;

        return this.boardHttpService.updateBoardTicket(boardTicket).pipe(tap((updatedBoardTicket) => this.boardTickets[index] = {...updatedBoardTicket}));
    }

    moveTicket(scheduledTicket: TimeBoardScheduledEvent, date: Date, buId: number): void {
        const existingResource = this.scheduledEvents.value.find((x) => x.ticketId === scheduledTicket.ticketId && x.unitId === scheduledTicket.unitId && x.from === scheduledTicket.from);
        const index = this.scheduledEvents.value.indexOf(existingResource);

        if (index == null) {
            return;
        }

        const movedTicket = new TimeBoardScheduledEvent(scheduledTicket.ticketId, buId, scheduledTicket.name, date, date, true, scheduledTicket.resource);
        const newScheduledTickets = this.scheduledEvents.value;
        newScheduledTickets[index] = movedTicket;
        this.scheduledEvents.next([...newScheduledTickets]);
    }

    private addResource(ticket: BoardTicketModel): void {
        this.boardTickets = [ticket, ...this.boardTickets];
        this.resources.next([new TimeBoardResourceTicket(ticket), ...this.resources.getValue()]);
    }

    private async setAssignees(board: BoardTransferModel): Promise<void> {
        const assignees = await this.httpService.getResources(board.id).toPromise();
        this.assignees.next([...assignees]);
    }
}
