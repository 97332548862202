import {Component, OnDestroy, OnInit} from '@angular/core';
import {BoardTypeEnum} from '@core/enums/board-type.enum';
import {BoardSelectFilter} from '@boards/components/board-select/board-select-filter/board-select-filter';
import {SubSink} from 'subsink';
import {BoardHttpService} from '@boards/board-http.service';
import {BoardModel} from '@boards/models';

@Component({
    selector: 'omt-board-select',
    templateUrl: './board-select.component.html',
    styleUrls: ['./board-select.component.scss']
})
export class BoardSelectComponent implements OnInit, OnDestroy {
    private subs = new SubSink();

    userBoards: BoardModel[] = [];
    teamBoards: BoardModel[] = [];
    projectBoards: BoardModel[] = [];
    allBoards: BoardModel[] = [];

    boardTypeEnum = BoardTypeEnum;
    filter = new BoardSelectFilter();

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    constructor(private readonly boardService: BoardHttpService) {
    }

    ngOnInit(): void {
        // Load boards
        this.loadBoards();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    loadBoards(): void {
        // Load boards
        this.subs.sink = this.boardService.readUserBoards().subscribe((x) => {
            this.userBoards = x;
            this.allBoards = [...this.allBoards, ...x];
        });

        this.subs.sink = this.boardService.readTeamBoards().subscribe((x) => {
            this.teamBoards = x;
            this.allBoards = [...this.allBoards, ...x];
        });

        this.subs.sink = this.boardService.readProjectBoards().subscribe((x) => {
            this.projectBoards = x;
            this.allBoards = [...this.allBoards, ...x];
        });
    }
}
