import {Component} from '@angular/core';
import {TimeBoardService} from '@app/boards/components/time-board/time-board.service';
import {isSameDay, isSameMonth} from 'date-fns';
import {CalendarEvent} from 'angular-calendar';

@Component({
    selector: 'omt-time-board-month',
    templateUrl: './time-board-month.component.html',
    styleUrls: ['./time-board-month.component.scss']
})
export class TimeBoardMonthComponent {
    constructor(public tbs: TimeBoardService) {
    }

    activeDayIsOpen = true;

    dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.tbs.viewDate)) {
            this.activeDayIsOpen = !((isSameDay(this.tbs.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0);

            this.tbs.viewDate = date;
        }
    }

}
