import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {BoardHeaderComponent} from '@app/boards/components/board-header/board-header.component';
import {SubSink} from 'subsink';
import {fuseAnimations} from '@fuse/animations';
import {BoardStateModel, BoardTicketModel, BoardTicketOrderModel, BoardTransferModel, BoardUnitEnum} from '@boards/models';
import {BoardHttpService} from '@boards/board-http.service';
import {TicketHttpService} from '@ticket/ticket-http.service';
import {SnackbarService} from '@core/services/snackbar.service';
import {MobileService} from '@core/services/global/mobile.service';
import {WindowRefService} from '@core/services/global/window-ref.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'omt-workflow-board',
    templateUrl: './work-flow-board.component.html',
    styleUrls: ['./work-flow-board.component.scss'],
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkFlowBoardComponent implements OnInit, OnDestroy {
    @Input() set board(board: BoardTransferModel) {
        this._board = {...new BoardTransferModel(null), ...board};
        this.states = [...board.boardStates];
    }

    get board(): BoardTransferModel {
        return this._board;
    }

    private _board: BoardTransferModel;

    @ViewChild('headerComponent', {static: true}) header: BoardHeaderComponent;

    @Input() hideCompleted: boolean;
    @Output() hideCompletedChange = new EventEmitter<boolean>();

    boardUnitEnum = BoardUnitEnum;
    showScheduler = false;
    addMode = false;
    newBoardState = new BoardStateModel();
    states: BoardStateModel[] = [];
    isTeam = false;
    isProject = false;
    fullHeight = 1000;
    isMobileDevice = this.mobileService.mobile;
    private subs = new SubSink();

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    constructor(private readonly boardService: BoardHttpService,
                private readonly ticketService: TicketHttpService,
                private readonly snackbarService: SnackbarService,
                private readonly containerRef: ElementRef,
                private readonly cdRef: ChangeDetectorRef,
                private readonly mobileService: MobileService,
                private readonly window: WindowRefService,
                private readonly translate: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.calculateFullHeight();

        this.subs.sink = this.boardService.ticketLinked.subscribe((newTicket: BoardTicketModel) => this.handleNewTicket(newTicket));

        this.subs.sink = this.boardService.boardTicketDeleted.subscribe((boardTicket: BoardTicketModel) => {
            const containingState = this.states.find((x) => x.id === boardTicket.boardStateId);

            if (containingState) {
                containingState.boardTickets = containingState.boardTickets.filter((x) => x.id !== boardTicket.id);
            } else {
                this.states[0].boardTickets = this.states[0].boardTickets.filter((x) => x.id !== boardTicket.id);
            }

        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    resize(): void {
        this.calculateFullHeight();
    }

    getDuration(state: BoardStateModel): { duration; x } {
        const dur = 350 * state.position + 'ms';
        return {duration: dur, x: '100%'};
    }

    getLastDuration(): { duration; x } {
        const dur = (350 * (this.states.length + 1)) + 'ms';
        return {duration: dur, x: '100%'};
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    saveNewState(): void {
        if (this.newBoardState.name == null || this.newBoardState.name === '') {
            this.snackbarService.open(this.translate.instant('BOARD.LIST_NAME_ERROR'));
            return;
        }

        this.newBoardState.boardId = this.board.id;
        this.newBoardState.position = this.states.length;

        this.subs.sink = this.boardService.createBoardState(this.newBoardState).subscribe((boardstate) => {
            this.newBoardState.id = boardstate.id;
            this.states = [...this.states, {...this.newBoardState}];
            this.newBoardState = new BoardStateModel();
            this.cdRef.detectChanges();
        });
    }

    dropState(event: CdkDragDrop<BoardStateModel[]>): void {
        moveItemInArray(this.states, event.previousIndex, event.currentIndex);
        if (event.previousIndex !== event.currentIndex) {
            this.boardService.recalculateAndUpdateStatePositions(this.states);
        }

    }

    removeState(id: number): void {
        this.states = this.states.filter((x) => x.id !== id);
    }

    dropTicket(state: BoardStateModel, event: CdkDragDrop<BoardTicketModel[]>): void {
        const item = event.previousContainer.data[event.previousIndex];
        item.boardStateId = state.id;
        item.sortNumber = event.currentIndex;
        item.updatedAt = new Date();

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            this.subs.sink = this.boardService.updateBoardTicket(item).subscribe((x) => {
                item.ticket.ticketState = x.ticket.ticketState;
                this.cdRef.markForCheck();
            });
        }

        this.recalculateSortNumbers(state);
    }

    handleNewTicket(boardTicket: BoardTicketModel): void {
        if (boardTicket.boardId !== this.board.id) {
            return;
        }

        const state =
            boardTicket.boardStateId ? this.states.find((x) => x.id === boardTicket.boardStateId) : this.states[0];

        if (state == null) {
            return;
        }
        state.boardTickets = [boardTicket, ...state.boardTickets];
        this.cdRef.detectChanges();
    }

    private calculateFullHeight(): void {
        const offset = 147;
        this.fullHeight = this.window.nativeWindow.innerHeight - 80 - offset;
        this.cdRef.detectChanges();
    }

    private recalculateSortNumbers(state: BoardStateModel): void {
        const ticketOrderModels: BoardTicketOrderModel[] = [];

        state.boardTickets.forEach((x, index) => {
            const orderModel = new BoardTicketOrderModel();
            orderModel.boardTicketId = x.id;
            orderModel.sortNumber = index;
            ticketOrderModels.push(orderModel);
        });

        this.subs.sink = this.boardService.updateBoardTicketOrder(ticketOrderModels).subscribe();
    }

    toggleScheduler(): void {
        this.showScheduler = !this.showScheduler;
    }
}
