import {Pipe, PipeTransform} from '@angular/core';
import {BoardSelectFilter} from '@boards/components/board-select/board-select-filter/board-select-filter';
import {ProjectStatusEnum} from '@core/enums/project-status.enum';
import {BoardModel, BoardUnitEnum} from '@boards/models';
import {stringIsNullOrWhiteSpace} from '@core/utils/string-utils';

@Pipe({
    name: 'boardSelectFilter',
    pure: false
})
export class BoardSelectFilterPipe implements PipeTransform {

    transform(value: BoardModel[], filter: BoardSelectFilter): BoardModel[] {
        if (!filter) {
            return value;
        }

        let filteredBoards = value;

        // search filter
        if (!stringIsNullOrWhiteSpace(filter.filterText)) {
            filteredBoards = filteredBoards.filter((board) => board.name.toLowerCase().includes(filter.filterText)
                || board.project?.name?.toLowerCase()?.includes(filter.filterText)
                || board.businessUnit?.name?.toLowerCase()?.includes(filter.filterText)
                || board.project?.customer?.name?.toLowerCase()?.includes(filter.filterText));
        }

        if (!filter.showUserBoards) {
            filteredBoards = filteredBoards.filter((x) => x.boardUnit !== BoardUnitEnum.Userboard);
        }

        if (!filter.showTeamBoards) {
            filteredBoards = filteredBoards.filter((x) => x.boardUnit !== BoardUnitEnum.Teamboard);
        }

        if (!filter.showProjectBoards) {
            filteredBoards = filteredBoards.filter((x) => x.boardUnit !== BoardUnitEnum.Projectboard);
        }

        if (filter.filterByDoneProjects) {
            filteredBoards = filteredBoards.filter((x) => !x.project || x.project.statusId !== ProjectStatusEnum.Abgeschlossen);
        }

        return filteredBoards;
    }
}
