import {CalendarEntry} from '@core/components/resource-scheduler-calendar/calendar-entry';

export const elementsOverlap = (elem1: HTMLElement, elem2: HTMLElement): boolean => {
    const rect1 = elem1.getBoundingClientRect();
    const rect2 = elem2.getBoundingClientRect();

    return rectsOverlap(rect1, rect2);
};

export const rectsOverlap = (rect1: { left: number; top: number; right: number; bottom: number }, rect2: {
    left: number;
    top: number;
    right: number;
    bottom: number;
}): boolean =>
    !(rect1.left >= rect2.right || rect1.top >= rect2.bottom || rect1.right <= rect2.left || rect1.bottom <= rect2.top);

export const isEntry = (obj: any): obj is CalendarEntry => 'resource' in obj;

export const getHourFromTimeSegmentElement = (element: HTMLElement): number => +element.getAttribute('hour');


