import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BoardSelectComponent} from '@boards/components/board-select/board-select.component';
import {AssignedTicketBoardComponent} from '@boards/components/assigned-ticket-board/assigned-ticket-board.component';
import {MotherBoardComponent} from '@boards/components/mother-board/mother-board.component';
import {BoardActionMenuComponent} from '@boards/components/board-action-menu/board-action-menu.component';


const routes: Routes = [
    {
        path: '',
        component: BoardSelectComponent
    },
    {
        path: 'assigned-ticket-board',
        component: AssignedTicketBoardComponent
    },
    {
        path: 'assigned-ticket-board/user/:userId',
        component: AssignedTicketBoardComponent
    },
    {
        path: 'assigned-ticket-board/customer/:customerId',
        component: AssignedTicketBoardComponent
    },
    {
        path: 'assigned-ticket-board/project/:projectId',
        component: AssignedTicketBoardComponent
    },
    {
        path: 'board-action-menu/:id',
        component: BoardActionMenuComponent
    },
    {
        path: ':id',
        component: MotherBoardComponent
    }
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BoardsRoutingModule {
}
