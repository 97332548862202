import {CalendarResource} from '@core/components/resource-scheduler-calendar/calendar-resource';
import {BoardTicketModel} from '@boards/models';

export class TimeBoardResourceTicket implements CalendarResource {
    constructor(boardTicket?: BoardTicketModel) {
        this.name = boardTicket.ticket?.title;
        this.ticketId = boardTicket.ticketId;
    }

    name: string;
    ticketId: number;
}
